import { PubSub, CONNECTION_STATE_CHANGE } from "@aws-amplify/pubsub";
import { Hub } from "aws-amplify";

import { useEffect, useState } from "react";

export const CONNECTION_STATE = {
  Connected: {
    title: "Connected",
    description: "Connected and working with no issues.",
  },
  ConnectedPendingDisconnect: {
    title: "Pending Disconnect",
    description:
      "The connection has no active subscriptions and is disconnecting.",
  },
  ConnectedPendingKeepAlive: {
    title: "Penging",
    description:
      "The connection is open, but has missed expected keep alive messages.",
  },
  ConnectedPendingNetwork: {
    title: "Pending Network",
    description:
      "The connection is open, but the network connection has been disrupted. When the network recovers, the connection will continue serving traffic.",
  },
  Connecting: {
    title: "Connecting...",
    description: "Attempting to connect.",
  },
  ConnectionDisrupted: {
    title: "Connection Disrupted",
    description: "The connection is disrupted and the network is available.",
  },
  ConnectionDisruptedPendingNetwork: {
    title: "Connection Disrupted",
    description:
      "The connection is disrupted and the network connection is unavailable.",
  },
  Disconnected: {
    title: "Disconnected",
    description: "Connection has no active subscriptions and is disconnecting.",
  },
};

export const DEVICE_ATTRIBUTE = {
  S1_HEATING_OFF_ON: "s1",
  S2_DOMESTIC_WATER_OFF_ON: "s2",
  S3_CH_THERMOSTATIC_SETPOINT: "s3",
  S4_DOMESTIC_WATER_SETPOINT: "s4",
  S5_SUPPLY_HEATING_OFF_ON: "s5",
  S6_RESET_ALARM: "s6",
  S7_POWERMETER_CONNECTED: "s7",
  S8_ENERGYMETER_CONNECTED: "s8",
  S9_CHOOSE_CH_TEMPERATURE_CONTOLLER: "s9",
  S10_CH_CURVE_SETPOINT: "s10",
  S11_SUPPLY_HEATING_INSTALLED: "s11",
  S12_DOMESTIC_HOT_WATER_INSTALLED: "s12",
  S13: "s13",
  V1_CH_TEMPERATURE: "v1",
  V2_DOMESTIC_WATER_TEMPERATURE: "v2",
  V3_FORWARD_TEMPERATURE: "v3",
  V4_RETURN_TEMPERATURE: "v4",
  V5_LOAD_HEATPUMP: "v5",
  V6_LOAD_SUPPLYHEATER: "v6",
  V7_POWER_CONSUMPTION: "v7",
  V8_ENERGY_PRODUCED: "v8",
  V9_FLOW: "v9",
  V10_COP: "v10",
  V11_SCOP: "v11",
  V12_EM_TEMP_IN: "v12",
  V13_EM_TEMP_OUT: "v13",
};

export function useSubscribeDevice(serialnumber) {
  const [dataMap, setDataMap] = useState({});
  const [connectionState, setConnectionState] = useState("Connecting");

  Hub.listen("pubsub", (data) => {
    const { payload } = data;
    if (payload.event === CONNECTION_STATE_CHANGE) {
      const connectionState = payload.data.connectionState;
      setConnectionState(connectionState);
    }
  });

  useEffect(() => {
    const userDataTopic = "HP/" + serialnumber + "/UserData";

    const sub1 = PubSub.subscribe(userDataTopic).subscribe({
      next: (data) => {
        const finalData = {};
        for (const [key, value] of Object.entries(data.value.data)) {
          if (
            [
              DEVICE_ATTRIBUTE.S7_POWERMETER_CONNECTED,
              DEVICE_ATTRIBUTE.S8_ENERGYMETER_CONNECTED,
              DEVICE_ATTRIBUTE.V5_LOAD_HEATPUMP,
              DEVICE_ATTRIBUTE.V6_LOAD_SUPPLYHEATER,
              DEVICE_ATTRIBUTE.V9_FLOW,
            ].includes(key)
          ) {
            finalData[key] = value;
          } else if (
            [
              DEVICE_ATTRIBUTE.V1_CH_TEMPERATURE,
              DEVICE_ATTRIBUTE.V2_DOMESTIC_WATER_TEMPERATURE,
              DEVICE_ATTRIBUTE.V3_FORWARD_TEMPERATURE,
              DEVICE_ATTRIBUTE.V4_RETURN_TEMPERATURE,
              DEVICE_ATTRIBUTE.V7_POWER_CONSUMPTION,
              DEVICE_ATTRIBUTE.V8_ENERGY_PRODUCED,
              DEVICE_ATTRIBUTE.V12_EM_TEMP_IN,
              DEVICE_ATTRIBUTE.V13_EM_TEMP_OUT,
            ].includes(key)
          ) {
            finalData[key] = convertWithDecimals(value, 1);
          } else if (
            [DEVICE_ATTRIBUTE.V10_COP, DEVICE_ATTRIBUTE.V11_SCOP].includes(key)
          ) {
            finalData[key] = convertWithDecimals(value, 2);
          } else {
            finalData[key] = value;
          }
        }
        setDataMap((prev) => ({ ...prev, ...finalData }));
      },
      error: (error) => {
        console.error("Error subscribing to topic", error);
      },
      complete: () => {
        console.log("Done");
      },
    });

    return () => {
      sub1.unsubscribe();
    };
  }, [serialnumber]);

  return {
    dataMap,
    connectionState,
  };
}

const convertWithDecimals = (value, decimals) => {
  const rounded = Math.round(value * 10) / 10;
  return rounded.toFixed(decimals);
};
