import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export function ValueIF({ condition, ifTrue, ifFalse, skeletonIf }) {
  const value = condition ? ifTrue : ifFalse;
  if (skeletonIf !== undefined && skeletonIf) return <Skeleton />;
  if (value === undefined) return <Skeleton />;
  return value;
}

export function Value({ value, skeletonIf }) {
  if (skeletonIf !== undefined ? skeletonIf : value) return <Skeleton />;
  return value;
}
