import React, { useState } from "react";
import { closeEye, eye } from "../../Base/SVG";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../context/use-auth";
import Dialog from "rc-dialog";
import "rc-dialog/assets/index.css";
import { Button } from "../../Base/primitives/Button";
import toast from "react-hot-toast";
import PasswordSchema from "../../config/schemas/PasswordSchema";
import { useTranslation } from "react-i18next";

const ChangePasswordSchema = Yup.object({
  old_password: Yup.string().required("Required"),
  ...PasswordSchema,
});

export default function ChangePassword() {
  const { t } = useTranslation();
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);
  const [busy, setBusy] = useState(false);
  const auth = useAuth();
  const [isShow, setIsShow] = useState(null);
  const handleSubmit = async (values, helpers) => {
    const doAction = async () => {
      const toastId = toast.loading("Changing owner...");

      try {
        setBusy(true);
        await auth.changePassword(values.old_password, values.new_password);
        toast.success(t("Password changed"), { id: toastId });
        helpers.resetForm();
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
        toast.error(err.message, { id: toastId });
      } finally {
        setBusy(false);
      }
    };

    doAction();
  };
  return (
    <Formik
      enableReinitialize
      initialValues={{
        old_password: "",
        new_password: "",
        confirm_password: "",
      }}
      validationSchema={ChangePasswordSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, submitForm, validateForm }) => (
        <div className="settBox">
          <Form autoComplete={"off"}>
            <h3>{t("Change Password")}</h3>
            <p>{t("Change your account password.")}</p>
            <div className="input__row">
              <div className="input__col">
                <div className="input__outer">
                  <label htmlFor="">{t("Old password")}</label>
                  <div className="input pass">
                    <Field
                      type={isShow === "oldPass" ? "text" : "password"}
                      name="old_password"
                      placeholder={t("Password")}
                    />
                    <button
                      onClick={() => {
                        if (isShow === "oldPass") setIsShow(null);
                        else setIsShow("oldPass");
                      }}
                      type="button"
                    >
                      {isShow === "oldPass" ? eye : closeEye}
                    </button>
                    <ErrorMessage name="old_password">
                      {(msg) => <small className="error">{msg}</small>}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="input__outer">
                  <label htmlFor="">{t("New password")}</label>
                  <div className="input pass">
                    <Field
                      type={isShow === "newPass" ? "text" : "password"}
                      name="new_password"
                      placeholder={t("Password")}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        if (isShow === "newPass") setIsShow(null);
                        else setIsShow("newPass");
                      }}
                    >
                      {isShow === "newPass" ? eye : closeEye}
                    </button>
                  </div>
                  <ErrorMessage name="new_password">
                    {(msg) => <small className="error">{msg}</small>}
                  </ErrorMessage>
                </div>
                <div className="input__outer">
                  <label htmlFor="">{t("Confirm Password")}</label>
                  <div className="input pass">
                    <Field
                      type={isShow === "confirm_password" ? "text" : "password"}
                      name="confirm_password"
                      placeholder={t("Confirm Password")}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        if (isShow === "confirm_password") setIsShow(null);
                        else setIsShow("confirm_password");
                      }}
                    >
                      {isShow === "confirm_password" ? eye : closeEye}
                    </button>
                  </div>
                  <ErrorMessage name="confirm_password">
                    {(msg) => <small className="error">{msg}</small>}
                  </ErrorMessage>
                </div>
              </div>
            </div>
            <div className="settBox__footer">
              <button type="button" className="button white sm">
                {t("Cancel")}
              </button>
              <button
                type="button"
                onClick={async (e) => {
                  const validationResult = await validateForm();
                  if (Object.keys(validationResult).length === 0) {
                    setConfirmDialogVisible(true);
                  }
                  e.preventDefault();
                }}
                className="button light sm"
              >
                {t("Apply")}
              </button>
            </div>
          </Form>
          <Dialog
            visible={confirmDialogVisible}
            title={"Change Account Password"}
            onClose={() => {
              setConfirmDialogVisible(false);
            }}
            footer={[
              <button
                type="button"
                className="button  sm"
                onClick={() => {
                  setConfirmDialogVisible(false);
                }}
              >
                {t("Cancel")}
              </button>,
              <Button
                onClick={() => {
                  submitForm();
                }}
                className="button light sm confirm-btn"
                disabled={busy}
                isLoading={busy}
                text={t("Confirm")}
              />,
            ]}
          >
            {t("Are you sure you want to change your account password?")}
            <div>
              {errors.submit && <span className="error">{errors.submit}</span>}
            </div>
          </Dialog>
        </div>
      )}
    </Formik>
  );
}
