import { ErrorMessage, FastField, Form, Formik } from "formik";
import React from "react";
import RICIBs from "react-individual-character-input-boxes";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Header from "../../../Base/Header";
import { getApi } from "../../../config/api";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const api = getApi.instance();

const AddDeviceSchema = Yup.object({
  serialNumber: Yup.string().required("Required"),
});

const DEVICE_SERIAL_NUMBER_LENGTH = 6;

export default function AddDevice({ setMenu, menu }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleFormSubmit = async ({ serialNumber }, helpers) => {
    const toastId = toast.loading("Adding device...");
    try {
      const result = await api.addHeatpump(serialNumber);
      const message = result?.data?.msg;

      if (message === "Access granted") {
        toast.success("Device added successfully", { id: toastId });
        navigate(-1);
      } else {
        let type = "error";

        if (["Serialnumber does not exist"].includes(message)) {
          type = "error";
        }

        if (["Allready access", "Ask owner for access"].includes(message)) {
          type = "error";
        }
        toast[type](t(message), { id: toastId });
      }
    } catch (err) {
      helpers.setStatus({ success: false });
      helpers.setErrors({ submit: err.message });
      helpers.setSubmitting(false);
      toast.error(err.message, { id: toastId });
    }
  };

  return (
    <>
      <Header title={t("Add Devices")} setMenu={setMenu} menu={menu} />
      <Formik
        initialValues={{ serialNumber: "" }}
        validationSchema={AddDeviceSchema}
        onSubmit={handleFormSubmit}
      >
        {({ errors, isSubmitting }) => (
          <Form>
            <div className="auto__container">
              <div className="device ab">
                <div className="deviceBox ">
                  <h3>{t("Add device")}</h3>
                  <p>
                    {t("Add the serial number of the device you want to add")}
                  </p>
                  <div className="deviceBox__inputs">
                    <FastField name="serialNumber">
                      {({ field }) => (
                        <RICIBs
                          amount={DEVICE_SERIAL_NUMBER_LENGTH}
                          autoFocus
                          handleOutputString={(value) => {
                            field.onChange({
                              target: {
                                name: field.name,
                                value,
                              },
                            });
                          }}
                          inputRegExp={/^[0-9]$/}
                          inputProps={Array.from({
                            length: DEVICE_SERIAL_NUMBER_LENGTH,
                          }).map((_, index) => ({
                            "data-testid": `serialNumber-${index}`,
                          }))}
                        />
                      )}
                    </FastField>
                    <div>
                      <ErrorMessage name="serialNumber">
                        {(msg) => <span className="error">{msg}</span>}
                      </ErrorMessage>
                    </div>
                    <div>
                      {errors.submit && (
                        <span className="error">{errors.submit}</span>
                      )}
                    </div>
                  </div>
                  <div className="deviceBox__footer">
                    <Link to="/devices" className="button white sm">
                      {t("Cancel")}
                    </Link>
                    <button
                      data-testid="Add Device Form / Button"
                      type="submit"
                      className="button light sm"
                      disabled={isSubmitting}
                    >
                      {t("Apply")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <Toaster />
    </>
  );
}
