import "./config/i18n";
import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { SplashScreen } from "./Base/SplashScreen";
import { AuthProvider, AuthConsumer } from "./context/auth-context";
import { Auth } from "./Base/Config/amplifyConfig";
import { Amplify } from "aws-amplify";
import { AWSIoTProvider } from "@aws-amplify/pubsub";
import awsExport from "./aws-exports";

Amplify.configure(awsExport);
Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: "eu-central-1",
    aws_pubsub_endpoint: process.env.REACT_APP_AWS_IOT_ENDPOINT,
  })
);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <AuthProvider Auth={Auth}>
      <AuthConsumer>
        {(auth) => (!auth.isInitialized ? <SplashScreen /> : <App />)}
      </AuthConsumer>
    </AuthProvider>
  </BrowserRouter>
);
