import * as Yup from "yup";

const PasswordSchema = {
  new_password: Yup.string()
    .required()
    .matches(
      /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
      "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character"
    ),
  confirm_password: Yup.string()
    .required()
    .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
};

export default PasswordSchema;
