import React from "react";
import { Route, Routes } from "react-router-dom";
import AddDevice from "./Pages/AddDevice";
import DetailDevice from "./Pages/DetailDevice/DetailDevice";
import TableDevices from "./Pages/TableDevices";

export default function Devices({ setMenu, menu }) {
  return (
    <>
      <Routes>
        <Route
          path=""
          element={<TableDevices setMenu={setMenu} menu={menu} />}
        />
        <Route
          path="/add-device"
          element={<AddDevice setMenu={setMenu} menu={menu} />}
        />
        <Route
          path="/detail-device/:deviceId"
          element={<DetailDevice setMenu={setMenu} menu={menu} />}
        />
      </Routes>
    </>
  );
}
