import { useTranslation } from "react-i18next";

export function SplashScreen() {
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <div
          style={{
            maxWidth: "16rem",
          }}
        >
          <div>
            <div>{t("Preparing application...")}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
