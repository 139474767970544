import UnitedKingdomFlagIcon from "./flags/UnitedKingdomFlagIcon";
import GermanyFlagIcon from "./flags/GermanyFlagIcon";
import DenmarkFlagIcon from "./flags/DenmarkFlagIcon";

export const resolveSupportedLanguages = (translate) => ({
  "en-US": {
    label: translate("English"),
    icon: UnitedKingdomFlagIcon,
  },
  de: {
    label: translate("German"),
    icon: GermanyFlagIcon,
  },
  dk: {
    label: translate("Danish"),
    icon: DenmarkFlagIcon,
  },
});
