import React from "react";
import { Link, NavLink } from "react-router-dom";
import { deviceIcon, logOutIcon, setIcon } from "./SVG";
import { useAuth } from "../context/use-auth";
import Logo from "./Logo";
import { useTranslation } from "react-i18next";

export default function Sidebar({ menu, closeFunc }) {
  const { t } = useTranslation();
  const { logout } = useAuth();
  return (
    <div className={"sidebar " + (menu ? "active" : "")} onClick={closeFunc}>
      <div className="sidebar__header">
        <Logo />
      </div>
      <div className="sidebar__inner">
        <div className="sidebar__inner-links">
          <NavLink to="devices" className="sidebar__inner-link">
            {deviceIcon} <span>{t("Devices")}</span>
          </NavLink>
          <NavLink to="settings" className="sidebar__inner-link">
            {setIcon} <span>{t("Settings")}</span>
          </NavLink>
        </div>
        <div className="sidebar__inner-links">
          <Link onClick={() => logout()} to="" className="sidebar__inner-link">
            {logOutIcon} <span>{t("Sign out")}</span>
          </Link>
          <div className="sidebar__version-outer">
            <span className="sidebar__inner-version">
              Version: {process.env.REACT_APP_VERSION}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
