import Fuse from "fuse.js";
import { useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import { useQueryState } from "./../../hooks/useQueryState";
import { dataForSkeleton } from "./dataForSkeleton";
import sortBy from "lodash.sortby";

export function useDataTable({ id, fetcher, columns, primaryKey }) {
  const { data: cacheData, error, isLoading, mutate } = useSWR(id, fetcher);
  const data = useMemo(() => {
    if (Array.isArray(cacheData)) {
      return cacheData;
    }
    return [];
  }, [cacheData]);
  const [searchValue, setSearchValue] = useQueryState(`${id}-search`);
  const [sortByValue, setSortByValue] = useQueryState(`${id}-sort-by`);
  const [dataModified, setDataModified] = useState(null);

  const fuse = useMemo(() => {
    if (!data?.length) return new Fuse([]);
    return new Fuse(data, {
      keys: columns
        .map((column) => column.dataIndex)
        .filter((withValue) => withValue),
    });
  }, [data, columns]);

  useEffect(() => {
    if (searchValue && searchValue !== "" && fuse._docs?.length) {
      const _searchResults = fuse.search(searchValue);
      setDataModified(_searchResults.map((row) => row.item));
    } else {
      setDataModified(null);
    }
  }, [fuse, searchValue]);

  useEffect(() => {
    if (!sortByValue) {
      return;
    }
    if (sortByValue && data?.length) {
      const _sortedData = sortBy(data, sortByValue);
      setDataModified(_sortedData);
    } else {
      setDataModified(null);
    }
  }, [data, sortByValue]);

  const sortyByColumns = useMemo(() => {
    return columns
      ?.filter((column) => column.sort)
      .map((column) => ({
        ...column,
        value: column.title,
      }));
  }, [columns]);

  const handleSortByOnChange = ({ dataIndex }) => {
    setSortByValue(dataIndex);
  };

  const afterDelete = (row) => {
    mutate(
      data.filter((dataItem) => dataItem[primaryKey] !== row[primaryKey]),
      false
    );
  };

  const handleOnSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const rows = dataModified || (isLoading ? dataForSkeleton(primaryKey) : data);
  return {
    rows,
    error,
    isLoading,
    handleSortByOnChange,
    sortyByColumns,
    sortByValue,
    searchValue,
    setSearchValue,
    afterDelete,
    handleOnSearchChange,
  };
}
