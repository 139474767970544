import React, { useState } from "react";
import { DataTable } from "../../../../Base/DataTable";
import { getApi } from "../../../../config/api";
import { RESOURCE_TYPE } from "../../../../context/delete-resource-context";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import { useAuth } from "../../../../context/use-auth";
import useSWR from "swr";

import "./Users.scss";
import { ChangeOwnerModal } from "./ChangeOwnerModal";
import { useTranslation } from "react-i18next";

const api = getApi.instance();

const buildColumns = ({ device, user, handleChangeOwner }) => {
  return [
    {
      title: "Email",
      render: (_, row) => {
        return <span>{row.Email}</span>;
      },
    },
    {
      title: "Status",
      render: UserStatusRender({
        device,
        user,
        handleChangeOwner,
      }),
    },
  ];
};

export default function Users({ device }) {
  const { t } = useTranslation();
  const id = device?.Serialnumber;
  const { user } = useAuth();
  const [changeOwnerModalVisible, setChangeOwnerModalVisible] = useState(false);
  const { data, mutate } = useSWR(`/devices/${device.Serialnumber}`);

  const handleChangeOwner = () => {
    setChangeOwnerModalVisible(true);
  };

  const onOwnerChange = ({ new_owner }) => {
    mutate({
      ...data,
      Owner: new_owner,
    });
  };

  return (
    <>
      <div>
        <DataTable
          id="user-access"
          primaryKey="Email"
          columns={buildColumns({
            device,
            user,
            handleChangeOwner,
          })}
          className={"deviceTable"}
          tableClassNames={"users"}
          fetcher={() =>
            api.getHeatpumpUsers(id).then((response) =>
              response.data?.map((Email) => ({
                Email,
              }))
            )
          }
          deleteResourceType={RESOURCE_TYPE.USER_ACCESS}
          beforeDeleteBegin={(row) => ({
            Serialnumber: id,
            user: row,
            isOwner:
              user?.identityId === device?.Owner && user?.email === row.Email,
          })}
        >
          <div className="deviceBox__header-more">
            <h3>{t("User access")}</h3>
          </div>
        </DataTable>
        <ChangeOwnerModal
          visible={changeOwnerModalVisible}
          setVisible={setChangeOwnerModalVisible}
          device={device}
          onComplete={onOwnerChange}
        />
      </div>
      <AddUserForm deviceId={id} />
    </>
  );
}

const AddUserSchema = Yup.object({
  email: Yup.string().email().required("Required"),
});

function AddUserForm({ deviceId }) {
  const { t } = useTranslation();
  const { data: users = [], mutate } = useSWR("user-access");
  const handleFormSubmit = async ({ email }, helpers) => {
    const toastId = toast.loading("Adding user...");
    try {
      const result = await api.addHeatpump(deviceId, {
        mail: email,
      });
      const message = result?.data?.msg;

      if (message === "Access granted") {
        toast.success(t("User access granted."), { id: toastId });
        mutate(
          [
            ...users,
            {
              Email: email,
            },
          ],
          true
        );
        helpers.resetForm();
      } else {
        let type = "error";

        if (["Serialnumber does not exist"].includes(message)) {
          type = "error";
        }

        if (["Allready access", "Ask owner for access"].includes(message)) {
          type = "error";
        }
        toast[type](t(message), { id: toastId });
      }
    } catch (err) {
      helpers.setStatus({ success: false });
      helpers.setErrors({ submit: err.message });
      helpers.setSubmitting(false);
      toast.error(err.message, { id: toastId });
    }
  };
  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={AddUserSchema}
      onSubmit={handleFormSubmit}
    >
      {({ errors, isSubmitting }) => (
        <Form>
          <div className="deviceTable__add">
            <div className="input">
              <Field type="text" name="email" />
              <ErrorMessage name="email">
                {(msg) => <span className="error">{msg}</span>}
              </ErrorMessage>
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="button sm light"
            >
              {t("Add user")}
            </button>
          </div>
          <div>
            {errors.submit && <span className="error">{errors.submit}</span>}
          </div>
          <Toaster />
        </Form>
      )}
    </Formik>
  );
}

function UserStatusRender({ user, device, handleChangeOwner }) {
  const { t } = useTranslation();
  return (_, { Email }) => {
    return (
      <div>
        {user?.identityId === device?.Owner && user?.email === Email ? (
          <span>
            {t("Owner")}{" "}
            <a
              href="/"
              className="btn"
              onClick={(e) => {
                e.preventDefault();
                handleChangeOwner(device.Serialnumber, Email);
              }}
            >
              {t("Change")}
            </a>
          </span>
        ) : null}
      </div>
    );
  };
}
