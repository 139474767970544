import "./styles/main.scss";
import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { SWRConfig } from "swr";
import AccountLayout from "./Base/AccountLayout";
import { AuthGuard } from "./Base/AuthGuard";
import { GuestGuard } from "./Base/GuestGuard";
import Sidebar from "./Base/Sidebar";
import { localStorageProvider } from "./config/localStorageProvider";
import { DeleteResourceProvider } from "./context/delete-resource-context";
import SignIn from "./Pages/Account/SignIn";
import SignUp from "./Pages/Account/SignUp";
import VerifyCode from "./Pages/Account/VerifyCode";
import ForgotPassword from "./Pages/Account/ForgotPassword";
import ResetPassword from "./Pages/Account/ResetPassword";
import Devices from "./Pages/Devices/Devices";
import Settings from "./Pages/Settings/Settings";

export default function App() {
  return (
    <>
      <Routes>
        <Route
          path="/*"
          element={
            <AuthGuard>
              <AppInner />
            </AuthGuard>
          }
        />
        <Route
          path="/account/sign-in"
          element={
            <GuestGuard>
              <AccountLayout>
                <SignIn />
              </AccountLayout>
            </GuestGuard>
          }
        />
        <Route
          path="/account/sign-up"
          element={
            <GuestGuard>
              <AccountLayout>
                <SignUp />
              </AccountLayout>
            </GuestGuard>
          }
        />
        <Route
          path="/account/verify-code"
          element={
            <GuestGuard>
              <AccountLayout>
                <VerifyCode />
              </AccountLayout>
            </GuestGuard>
          }
        />
        <Route
          path="/account/forgot-password"
          element={
            <GuestGuard>
              <AccountLayout>
                <ForgotPassword />
              </AccountLayout>
            </GuestGuard>
          }
        />
        <Route
          path="/account/reset-password"
          element={
            <GuestGuard>
              <AccountLayout>
                <ResetPassword />
              </AccountLayout>
            </GuestGuard>
          }
        />
      </Routes>
    </>
  );
}

const AppInner = () => {
  const [menu, setMenu] = useState(false);
  const location = useLocation();
  const closeFunc = (e) => {
    if (e.target === e.currentTarget) setMenu(false);
  };

  useEffect(() => {
    if (menu) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [menu]);

  useEffect(() => {
    setMenu(false);
    document.body.classList.remove("active");
  }, [location]);
  return (
    <SWRConfig value={{ provider: localStorageProvider }}>
      <DeleteResourceProvider>
        <div className="wrapper">
          <Sidebar closeFunc={closeFunc} menu={menu} />
          <main className="main">
            <Routes>
              <Route
                path="/devices/*"
                element={<Devices menu={menu} setMenu={setMenu} />}
              />
              <Route
                path="/settings"
                element={<Settings menu={menu} setMenu={setMenu} />}
              />
              <Route
                path="*"
                element={<Navigate to="/devices" replace={true} />}
              />
            </Routes>
          </main>
        </div>
      </DeleteResourceProvider>
    </SWRConfig>
  );
};
