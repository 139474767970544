import Dialog from "rc-dialog";
import "rc-dialog/assets/index.css";
import * as Yup from "yup";
import { useFormikContext, Formik, FastField } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/use-auth";
import Loader from "../../Base/primitives/Loader";
import RICIBs from "react-individual-character-input-boxes";
import "./style.scss";
import toast from "react-hot-toast";

const ChangeEmailModalSchema = Yup.object({
  verification_code: Yup.string().required("Required"),
});

const AutoSubmitToken = () => {
  const { values, submitForm } = useFormikContext();
  useEffect(() => {
    // Submit the form imperatively as an effect as soon as form values.token are 6 digits long
    if (values?.verification_code?.length === 6) {
      submitForm();
    }
  }, [values, submitForm]);
  return null;
};

export default function ChangeEmailModal({ changeEmailModalMeta, onClose }) {
  const auth = useAuth();
  const [error, setError] = useState(null);
  const [sendingVerificationCode, setSendingVerificationCode] = useState(false);
  const [busyVerifyingConfirmationCode, setBusyVerifyingConfirmationCode] =
    useState(false);

  const { t } = useTranslation();
  const handleSubmit = async (values, helpers) => {
    const id = toast.loading(t("Verifying confirmation code..."));

    try {
      setBusyVerifyingConfirmationCode(true);
      await auth.verifyCurrentUserAttributeSubmit(
        "email",
        values.verification_code
      );
      helpers.resetForm();
      toast.success("Email changed successfully", {
        id,
      });
      onClose({
        error,
      });
    } catch (err) {
      toast.error(err.message, {
        id,
      });
      helpers.setStatus({ success: false });
      helpers.setErrors({ submit: err.message });
      helpers.setSubmitting(false);
    } finally {
      setBusyVerifyingConfirmationCode(false);
    }
  };

  useEffect(() => {
    const doAction = async () => {
      setError(null);
      try {
        await auth.updateUserAttributes({
          email: changeEmailModalMeta.email,
        });
      } catch (e) {
        setError(e);
      } finally {
        setSendingVerificationCode(false);
      }
    };

    if (
      changeEmailModalMeta?.email &&
      !changeEmailModalMeta?.already_sent_verification_code
    ) {
      setSendingVerificationCode(true);
      setTimeout(() => {
        doAction();
      }, 1000);
    }

    return () => {
      setSendingVerificationCode(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeEmailModalMeta]);

  return (
    <Formik
      initialValues={{
        new_owner: "",
      }}
      onSubmit={handleSubmit}
      validationSchema={ChangeEmailModalSchema}
    >
      <Dialog
        visible={changeEmailModalMeta}
        title={t("Change Account Email")}
        onClose={() => {
          onClose({
            error,
          });
        }}
        footer={
          sendingVerificationCode
            ? []
            : [
                <button
                  type="button"
                  className="button  sm"
                  onClick={() => {
                    onClose({
                      error,
                    });
                  }}
                >
                  {t("Cancel")}
                </button>,
              ]
        }
      >
        {sendingVerificationCode ? (
          <div className="flex flex-col items-center justify-center">
            <Loader
              width={50}
              height={50}
              className={"spinner"}
              stroke="black"
            />
            <p className="text-center">Sending verification code...</p>
          </div>
        ) : error ? (
          <div>
            <p className="invalid-feedback">{error.message}</p>
          </div>
        ) : (
          <div className="input__row">
            <div className="input__col">
              <label htmlFor="">{t("Confirmation Code")}</label>
              <div className="content-center">
                <FastField name="verification_code">
                  {({ field }) => (
                    <RICIBs
                      amount={6}
                      autoFocus
                      handleOutputString={(value) => {
                        field.onChange({
                          target: {
                            name: field.name,
                            value,
                          },
                        });
                      }}
                      inputRegExp={/^[0-9]$/}
                      inputProps={{
                        className: "confirm-input",
                        disabled: busyVerifyingConfirmationCode,
                      }}
                    />
                  )}
                </FastField>
              </div>
              <a
                href="/"
                onClick={async (e) => {
                  e.preventDefault();
                  await auth.updateUserAttributes({
                    email: changeEmailModalMeta.email,
                  });
                  toast.success("Verification code sent successfully");
                }}
              >
                <small>{t("Request New Code")}</small>{" "}
              </a>
            </div>
          </div>
        )}
        <AutoSubmitToken />
      </Dialog>
    </Formik>
  );
}
