import React, { useState } from "react";
import { closeEye, eye } from "../../Base/SVG";
import { mailIcon } from "../../Base/SVG";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage, Form } from "formik";
import cx from "classnames";
import { useAuth } from "../../context/use-auth";
import toast, { Toaster } from "react-hot-toast";
import PasswordSchema from "../../config/schemas/PasswordSchema";
import Logo from "../../Base/Logo";
import { useTranslation } from "react-i18next";

const SignInSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  code: Yup.string().required("Code is required"),
  ...PasswordSchema,
});

export default function ResetPassword() {
  const { t } = useTranslation();
  const { passwordReset } = useAuth();
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState(null);
  const handleFormSubmit = async (values, helpers) => {
    const id = toast.loading("Resetting password...");
    try {
      await passwordReset(values.email, values.code, values.new_password);
      navigate("/account/sign-in");
      helpers.setSubmitting(false);
      toast.success("Password reset successful!", { id });
    } catch (err) {
      helpers.setStatus({ success: false });
      helpers.setErrors({ submit: err.message });
      helpers.setSubmitting(false);
      toast.error(err.message, { id });
    }
  };

  return (
    <Formik
      initialValues={{
        email: "",
        code: "",
        new_password: "",
        confirm_password: "",
      }}
      validationSchema={SignInSchema}
      onSubmit={handleFormSubmit}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <div className="authForm">
            <div className="authForm__inner">
              <div className="authForm__logo">
                <Logo />
              </div>

              <div className="input__outer">
                <div
                  className={cx("input icon", {
                    error: errors.email && touched.email,
                  })}
                >
                  <Field name="email" type="email" placeholder="Email" />
                  {mailIcon}
                  <ErrorMessage name="email">
                    {(msg) => <span className="error">{msg}</span>}
                  </ErrorMessage>
                </div>
              </div>
              <div className="input__outer">
                <div
                  className={cx("input", {
                    error: errors.code && touched.code,
                  })}
                >
                  <Field
                    name="code"
                    type="input"
                    placeholder={t("Verification Code")}
                  />
                  <ErrorMessage name="code">
                    {(msg) => <span className="error">{msg}</span>}
                  </ErrorMessage>
                </div>
              </div>
              <div className="input__outer">
                <label htmlFor="">{t("New password")}</label>
                <div className="input pass">
                  <Field
                    type={isShow === "newPass" ? "text" : "password"}
                    name="new_password"
                    placeholder={t("Password")}
                  />
                  <button
                    type="button"
                    onClick={() => {
                      if (isShow === "newPass") setIsShow(null);
                      else setIsShow("newPass");
                    }}
                  >
                    {isShow === "newPass" ? eye : closeEye}
                  </button>
                </div>
                <ErrorMessage name="new_password">
                  {(msg) => <small className="error">{msg}</small>}
                </ErrorMessage>
              </div>
              <div className="input__outer">
                <label htmlFor="">{t("Confirm Password")}</label>
                <div className="input pass">
                  <Field
                    type={isShow === "confirm_password" ? "text" : "password"}
                    name="confirm_password"
                    placeholder={t("Confirm Password")}
                  />
                  <button
                    type="button"
                    onClick={() => {
                      if (isShow === "confirm_password") setIsShow(null);
                      else setIsShow("confirm_password");
                    }}
                  >
                    {isShow === "confirm_password" ? eye : closeEye}
                  </button>
                </div>
                <ErrorMessage name="confirm_password">
                  {(msg) => <small className="error">{msg}</small>}
                </ErrorMessage>
              </div>
              <div>
                {errors.submit && (
                  <span className="error">{errors.submit}</span>
                )}
              </div>
              <div className="authForm__btn">
                <button
                  type="submit"
                  className="button light"
                  disabled={isSubmitting}
                >
                  {t("Reset Password")}
                </button>
              </div>
            </div>
            <Toaster />
          </div>
        </Form>
      )}
    </Formik>
  );
}
