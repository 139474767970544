import React from "react";
import { mailIcon } from "../../Base/SVG";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage, Form } from "formik";
import cx from "classnames";
import { useAuth } from "../../context/use-auth";
import toast, { Toaster } from "react-hot-toast";
import Logo from "../../Base/Logo";
import { useTranslation } from "react-i18next";

const FormSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
});

export default function ForgotPassword() {
  const { t } = useTranslation();
  const { passwordRecovery } = useAuth();
  const navigate = useNavigate();

  const handleFormSubmit = async (values, helpers) => {
    const id = toast.loading("Sending recovery code...");
    try {
      await passwordRecovery(values.email);
      helpers.setSubmitting(false);
      toast.success(
        t("Recovery code sent! Redirecting to verification page..."),
        {
          id,
        }
      );
      setTimeout(() => {
        navigate("/account/reset-password");
      }, 500);
    } catch (err) {
      toast.error(err.message, { id });
      helpers.setStatus({ success: false });
      helpers.setErrors({ submit: err.message });
      helpers.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={FormSchema}
      onSubmit={handleFormSubmit}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <div className="authForm">
            <div className="authForm__inner">
              <div className="authForm__logo">
                <Logo />
              </div>
              <p>
                {t(`We'll send a recovery code to this email if an account is
                associated with it.`)}{" "}
              </p>
              <div className="input__outer">
                <div
                  className={cx("input icon", {
                    error: errors.email && touched.email,
                  })}
                >
                  <Field name="email" type="email" placeholder={t("Email")} />
                  {mailIcon}
                  <ErrorMessage name="email">
                    {(msg) => <span className="error">{msg}</span>}
                  </ErrorMessage>
                </div>
              </div>
              <div>
                {errors.submit && (
                  <span className="error">{errors.submit}</span>
                )}
              </div>
              <div className="authForm__btn">
                <button
                  type="submit"
                  className="button light"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? t("Submitting...") : t("Submit")}
                </button>
              </div>
            </div>
            <Toaster />
          </div>
        </Form>
      )}
    </Formik>
  );
}
