import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, createSearchParams, useLocation } from "react-router-dom";
import { useAuth } from "../context/use-auth";

export const AuthGuard = (props) => {
  const { children } = props;
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (!auth.isAuthenticated) {
      navigate({
        pathname: "/account/sign-in",
        search: createSearchParams({
          returnUrl: location.pathname,
        }).toString(),
      });
    } else {
      setChecked(true);
    }
  }, [auth, location.pathname, navigate]);

  if (!checked) {
    return null;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // authenticated / authorized.

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};
