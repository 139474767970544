import React from "react";
import toast from "react-hot-toast";
import { DataTable } from "../../Base/DataTable";
import { getApi } from "../../config/api";
import useSWR from "swr";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/use-auth";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";

const api = getApi.instance();

const buildColumns = ({ t }) => {
  return [
    {
      dataIndex: "Name",
      title: t("Name"),
    },
    {
      dataIndex: "Serialnumber",
      title: t("Serial number"),
    },
    {
      dataIndex: "PhoneMessage",
      title: t("Notify by SMS"),
      render: NotifyBy,
    },
    {
      dataIndex: "MailMessage",
      title: t("Notify by email"),
      render: NotifyBy,
    },
  ];
};

export function NotifyBy(_, row, index, column) {
  const auth = useAuth();
  const { t } = useTranslation();
  const [busy, setBusy] = React.useState(false);

  const { data: notificationPreferences, mutate } = useSWR(
    "notification-preferences"
  );
  const checked = notificationPreferences?.find(
    (preference) => preference.Serialnumber === row.Serialnumber
  )?.[column.dataIndex];

  const hadleNotificationPreferenceChange = async (e) => {
    setBusy(true);
    const checked = e.target.checked;
    const id = toast.loading("Saving...");
    try {
      const phone =
        column.dataIndex === "PhoneMessage" && checked
          ? {
              Phonenumber: auth?.user?.phone_number,
            }
          : { Phonenumber: "" };

      await api.updateNotificationPreferences({
        Serialnumber: row.Serialnumber,
        [column.dataIndex]: checked,
        ...phone,
      });

      const mutatePayload = notificationPreferences.map((preference) => {
        if (preference.Serialnumber === row.Serialnumber) {
          return {
            ...preference,
            [column.dataIndex]: checked,
            ...phone,
          };
        }
        return preference;
      });

      const existingPreference = notificationPreferences?.find(
        (preference) => preference.Serialnumber === row.Serialnumber
      );
      if (!existingPreference) {
        mutatePayload.push({
          Serialnumber: row.Serialnumber,
          [column.dataIndex]: checked,
          ...phone,
        });
      }

      mutate(mutatePayload, false);

      toast.success(t("Notification preference saved"), { id });
    } catch (e) {
      toast.error(t("Error saving notification preference"), { id });
    } finally {
      setBusy(false);
    }
  };

  const disableMessage =
    column.dataIndex === "PhoneMessage" && !auth?.user?.phone_number
      ? t("Please add a phone number to your profile")
      : null;

  const Container = ({ children }) => {
    if (disableMessage) {
      return (
        <Tooltip
          placement="top"
          trigger={["hover"]}
          overlay={<span>{disableMessage}</span>}
        >
          {children}
        </Tooltip>
      );
    }
    return children;
  };

  return (
    <div>
      <Container>
        <div className="checky">
          {busy ? (
            <Skeleton />
          ) : (
            <>
              <input
                type="checkbox"
                disabled={busy || disableMessage}
                checked={checked}
                onChange={hadleNotificationPreferenceChange}
              />
              <label htmlFor=""></label>
            </>
          )}
        </div>
      </Container>
    </div>
  );
}
export default function Notifications() {
  const { t } = useTranslation();
  const { isLoading } = useSWR("notification-preferences", async () => {
    const result = await api
      .getNotificationPreferences()
      .then((response) => response.data);
    return result;
  });
  return (
    <div className="settBox">
      <div className="settBox__header">
        <h3>{t("Notifications")}</h3>
      </div>
      <DataTable
        id="devices"
        primaryKey="Serialnumber"
        className="settTable"
        columns={buildColumns({ t })}
        fetcher={() => api.getHeatpumps().then((response) => response.data)}
        omitDeleteAction
      />
      {isLoading && <Skeleton />}
    </div>
  );
}
