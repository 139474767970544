import React from "react";
import RangeSlider from "./RangeSlider";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import { DEVICE_ATTRIBUTE, useSubscribeDevice } from "./useSubscribeDevice";
import debounce from "lodash.debounce";
import { publishToIoT } from "./publishToIoT";
import toster from "react-hot-toast";
import { useTranslation } from "react-i18next";

const FormSchema = Yup.object({});

export default function Settings({ id }) {
  const { t } = useTranslation();
  const { dataMap: deviceMeta } = useSubscribeDevice(id);
  const heatingSetpointOnBlur = debounce((e) => {
    publishToIoT(id, {
      [DEVICE_ATTRIBUTE.S3_CH_THERMOSTATIC_SETPOINT]: e.target.value,
    });
    toster.success("Saved!");
  });

  const domesticWaterSetpointOnBlur = debounce((e) => {
    publishToIoT(id, {
      [DEVICE_ATTRIBUTE.S4_DOMESTIC_WATER_SETPOINT]: e.target.value,
    });
    toster.success("Saved!");
  });

  const heatingOnChange = debounce((e) => {
    publishToIoT(id, {
      [DEVICE_ATTRIBUTE.S1_HEATING_OFF_ON]: e.target.checked,
    });
    toster.success("Saved!");
  }, 1000);

  const domesticWaterOnChange = debounce((e) => {
    publishToIoT(id, {
      [DEVICE_ATTRIBUTE.S2_DOMESTIC_WATER_OFF_ON]: e.target.checked,
    });
    toster.success("Saved!");
  }, 1000);

  const supplyHeatingOnChange = debounce((e) => {
    publishToIoT(id, {
      [DEVICE_ATTRIBUTE.S5_SUPPLY_HEATING_OFF_ON]: e.target.checked,
    });
    toster.success("Saved!");
  }, 1000);

  if (deviceMeta[DEVICE_ATTRIBUTE.S13] === true) {
    return null;
  }

  return (
    <div className="deviceBox">
      <div className="deviceBox__header">
        <h3>{t("Settings")}</h3>
      </div>

      <Formik
        enableReinitialize
        initialValues={{
          [DEVICE_ATTRIBUTE.V1_CH_TEMPERATURE]:
            deviceMeta?.[DEVICE_ATTRIBUTE.V1_CH_TEMPERATURE],
          [DEVICE_ATTRIBUTE.V2_DOMESTIC_WATER_TEMPERATURE]:
            deviceMeta?.[DEVICE_ATTRIBUTE.V2_DOMESTIC_WATER_TEMPERATURE],
          [DEVICE_ATTRIBUTE.S1_HEATING_OFF_ON]:
            deviceMeta?.[DEVICE_ATTRIBUTE.S1_HEATING_OFF_ON],
          [DEVICE_ATTRIBUTE.S2_DOMESTIC_WATER_OFF_ON]:
            deviceMeta?.[DEVICE_ATTRIBUTE.S2_DOMESTIC_WATER_OFF_ON],
          [DEVICE_ATTRIBUTE.S5_SUPPLY_HEATING_OFF_ON]:
            deviceMeta?.[DEVICE_ATTRIBUTE.S5_SUPPLY_HEATING_OFF_ON],
          [DEVICE_ATTRIBUTE.S3_CH_THERMOSTATIC_SETPOINT]:
            deviceMeta?.[DEVICE_ATTRIBUTE.S3_CH_THERMOSTATIC_SETPOINT],
          [DEVICE_ATTRIBUTE.S4_DOMESTIC_WATER_SETPOINT]:
            deviceMeta?.[DEVICE_ATTRIBUTE.S4_DOMESTIC_WATER_SETPOINT],
        }}
        validationSchema={FormSchema}
      >
        {({ values, setFieldValue, handleBlur, handleChange }) => (
          <Form>
            {/* {connectionState !== "Connected" && (
            <div className="alert alert-warning">
              <h4> {t(CONNECTION_STATE[connectionState].title)}</h4>
              <hr />
              <p>{t(CONNECTION_STATE[connectionState].description)}</p>
            </div>
          )} */}
            <div className="deviceTable">
              <table className="settTable">
                <thead>
                  <tr>
                    <th>{t("Property")}</th>
                    <th>{t("Edit temp")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{t("Heating Setpoint")}</td>
                    <td>
                      <div className="edit">
                        <div className="input">
                          <Field
                            type="number"
                            min={20}
                            max={70}
                            name={DEVICE_ATTRIBUTE.S3_CH_THERMOSTATIC_SETPOINT}
                            disabled={
                              values[DEVICE_ATTRIBUTE.S1_HEATING_OFF_ON] ===
                              undefined
                            }
                            onBlur={(e) => {
                              handleBlur(e);
                              heatingSetpointOnBlur(e);
                            }}
                          />
                          <small>°C</small>
                        </div>
                        <RangeSlider
                          min={20}
                          max={70}
                          disabled={
                            values[
                              DEVICE_ATTRIBUTE.S3_CH_THERMOSTATIC_SETPOINT
                            ] === undefined
                          }
                          value={
                            values[DEVICE_ATTRIBUTE.S3_CH_THERMOSTATIC_SETPOINT]
                          }
                          onBlur={heatingSetpointOnBlur}
                          onChange={(e) => {
                            setFieldValue(
                              DEVICE_ATTRIBUTE.S3_CH_THERMOSTATIC_SETPOINT,
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </td>
                  </tr>

                  {deviceMeta[
                    DEVICE_ATTRIBUTE.S12_DOMESTIC_HOT_WATER_INSTALLED
                  ] ? (
                    <tr>
                      <td>{t("Domestic Water Setpoint")}</td>

                      <td>
                        <div className="edit">
                          <div className="input">
                            <Field
                              type="number"
                              min={20}
                              max={70}
                              name={DEVICE_ATTRIBUTE.S4_DOMESTIC_WATER_SETPOINT}
                              onBlur={(e) => {
                                handleBlur(e);
                                domesticWaterSetpointOnBlur(e);
                              }}
                              disabled={
                                values[
                                  DEVICE_ATTRIBUTE.S4_DOMESTIC_WATER_SETPOINT
                                ] === undefined
                              }
                            />
                            <small>°C</small>
                          </div>
                          <RangeSlider
                            disabled={
                              values[
                                DEVICE_ATTRIBUTE.S4_DOMESTIC_WATER_SETPOINT
                              ] === undefined
                            }
                            min={20}
                            max={70}
                            value={
                              values[
                                DEVICE_ATTRIBUTE.S4_DOMESTIC_WATER_SETPOINT
                              ]
                            }
                            onChange={(e) => {
                              setFieldValue(
                                DEVICE_ATTRIBUTE.S4_DOMESTIC_WATER_SETPOINT,
                                e.target.value
                              );
                            }}
                            onBlur={domesticWaterSetpointOnBlur}
                          />
                        </div>
                      </td>
                    </tr>
                  ) : null}

                  <tr>
                    <td>{t("Heating")}</td>
                    <td>
                      <div className="checky">
                        <Field
                          name={DEVICE_ATTRIBUTE.S1_HEATING_OFF_ON}
                          type="checkbox"
                          onChange={(e) => {
                            handleChange(e);
                            heatingOnChange(e);
                          }}
                          disabled={
                            values[DEVICE_ATTRIBUTE.S1_HEATING_OFF_ON] ===
                            undefined
                          }
                        />
                        <label htmlFor=""></label>
                      </div>
                    </td>
                  </tr>
                  {deviceMeta[
                    DEVICE_ATTRIBUTE.S12_DOMESTIC_HOT_WATER_INSTALLED
                  ] && (
                    <tr>
                      <td>{t("Domestic Water")}</td>
                      <td>
                        <div className="checky">
                          <Field
                            name={DEVICE_ATTRIBUTE.S2_DOMESTIC_WATER_OFF_ON}
                            type="checkbox"
                            onChange={(e) => {
                              handleChange(e);
                              domesticWaterOnChange(e);
                            }}
                            disabled={
                              values[DEVICE_ATTRIBUTE.S1_HEATING_OFF_ON] ===
                              undefined
                            }
                          />
                          <label htmlFor=""></label>
                        </div>
                      </td>
                    </tr>
                  )}
                  {deviceMeta[
                    DEVICE_ATTRIBUTE.S11_SUPPLY_HEATING_INSTALLED
                  ] && (
                    <tr>
                      <td>{t("Supply Heating")}</td>
                      <td>
                        <div className="checky">
                          <Field
                            name={DEVICE_ATTRIBUTE.S5_SUPPLY_HEATING_OFF_ON}
                            type="checkbox"
                            onChange={(e) => {
                              handleChange(e);
                              supplyHeatingOnChange(e);
                            }}
                            disabled={
                              values[DEVICE_ATTRIBUTE.S1_HEATING_OFF_ON] ===
                              undefined
                            }
                          />
                          <label htmlFor=""></label>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
