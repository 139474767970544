import React, { useState } from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../context/use-auth";
import ChangeEmailModal from "./ChangeEmailModal";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

const validateEmail = (value, _, { t }) => {
  if (!value) {
    return t("Email is required");
  }

  if (!Yup.string().required().email().isValidSync(value)) {
    return t("Invalid email");
  }

  return undefined;
};

const validatePhoneNumber = (value, formikProps, { t, prevValue }) => {
  if (!formikProps.touched.phone_number) {
    return undefined;
  }

  if (value === prevValue) {
    return undefined;
  }

  if (!value) {
    return t("Phone number is required");
  }

  if (!isValidPhoneNumber(value)) {
    return t("Invalid phone number");
  }

  return undefined;
};

export default function Account() {
  const { t } = useTranslation();
  const [changeEmailModalMeta, setChangeEmailModalMeta] = useState(null);
  const auth = useAuth();

  const handleFormSubmit = async (values) => {
    if (values.email !== auth.user.email) {
      return setChangeEmailModalMeta({
        email: values.email,
      });
    }

    if (values.phone_number !== auth.user.phone_number) {
      toast.promise(
        auth.updateUserAttributes({ phone_number: values.phone_number }),
        {
          loading: t("Updating phone number..."),
          success: t("Phone number updated!"),
          error: t("Error updating phone number."),
        }
      );
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          email: auth?.user?.email,
          phone_number: auth?.user?.phone_number,
        }}
        onSubmit={handleFormSubmit}
      >
        {(formikProps) => (
          <div className="settBox">
            <Form autoComplete={"off"}>
              <h3>{t("Account")}</h3>
              <p>
                {t(
                  `Change your account information and/or add phone number. This can be done an unlimited amount of times.`
                )}
              </p>
              <div className="input__row">
                <div className="input__col">
                  <div className="input__outer">
                    <label htmlFor="">Email</label>
                    <div className="input">
                      <Field
                        name="email"
                        type="email"
                        placeholder={t("Email")}
                        autoComplete="off"
                        validate={(value) =>
                          validateEmail(value, formikProps, {
                            t,
                          })
                        }
                      />
                      {!auth?.user?.email_verified ? (
                        <small className="error">
                          <a
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              setChangeEmailModalMeta({
                                email: auth.user.email,
                                already_sent_verification_code: true,
                              });
                            }}
                          >
                            {t("Email not verified")}
                          </a>
                        </small>
                      ) : null}
                    </div>
                    <ErrorMessage name="email">
                      {(msg) => <span className="invalid-feedback">{msg}</span>}
                    </ErrorMessage>
                  </div>
                  <div className="input__outer">
                    <label htmlFor="">{t("Phone number")}</label>
                    <div className="input">
                      <Field
                        name="phone_number"
                        placeholder={t("Phone number")}
                        validate={(value) =>
                          validatePhoneNumber(value, formikProps, {
                            t,
                            prevValue: auth?.user?.phone_number,
                          })
                        }
                      >
                        {({ field, form }) => (
                          <PhoneInput
                            {...field}
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry="DK"
                            placeholder={t("Phone number")}
                            onChange={(value) =>
                              form.setFieldValue("phone_number", value)
                            }
                          />
                        )}
                      </Field>
                    </div>
                    <ErrorMessage name="phone_number">
                      {(msg) => <span className="invalid-feedback">{msg}</span>}
                    </ErrorMessage>
                  </div>
                </div>
              </div>
              <div className="settBox__footer">
                <button type="button" className="button white sm">
                  {t("Cancel")}
                </button>
                <button type="submit" className="button light sm">
                  {t("Apply")}
                </button>
              </div>
            </Form>
            <ChangeEmailModal
              changeEmailModalMeta={changeEmailModalMeta}
              onClose={({ error }) => {
                setChangeEmailModalMeta(null);
                if (error) {
                  formikProps.setFieldValue("email", auth?.user?.email);
                  toast.error(t("Error changing email"));
                }
              }}
            />
          </div>
        )}
      </Formik>
    </>
  );
}
