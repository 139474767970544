import React from "react";
import { Command } from "cmdk";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function Commands({ children }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const ref = React.useRef(null);
  const [inputValue, setInputValue] = React.useState("");

  const [pages, setPages] = React.useState(["home"]);
  const activePage = pages[pages.length - 1];
  const isHome = activePage === "home";

  const popPage = React.useCallback(() => {
    setPages((pages) => {
      const x = [...pages];
      x.splice(-1, 1);
      return x;
    });
  }, []);

  function bounce() {
    if (ref.current) {
      ref.current.style.transform = "scale(0.96)";
      setTimeout(() => {
        if (ref.current) {
          ref.current.style.transform = "";
        }
      }, 100);

      setInputValue("");
    }
  }

  React.useEffect(() => {
    const down = (e) => {
      if (e.key === "k" && e.metaKey) {
        setOpen((open) => !open);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  return (
    <>
      {React.cloneElement(children, { onClick: () => setOpen(true) })}
      <div className="commands">
        <Command.Dialog
          open={open}
          onOpenChange={setOpen}
          ref={ref}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              bounce();
            }

            if (isHome || inputValue.length) {
              return;
            }

            if (e.key === "Backspace") {
              e.preventDefault();
              popPage();
              bounce();
            }
          }}
        >
          <div>
            {pages.map((p) => (
              <div key={p} cmdk-vercel-badge="">
                {p}
              </div>
            ))}
          </div>
          <Command.Input
            autoFocus
            placeholder={t("What do you need?")}
            onValueChange={(value) => {
              setInputValue(value);
            }}
          />
          <Command.List>
            <Command.Empty>{t("No results found.")}</Command.Empty>
            {activePage === "home" && (
              <Home
                listDevices={() => {
                  navigate("/devices");
                }}
                addNewDevice={() => {
                  navigate("/devices/add-device");
                }}
                accountSettings={() => {
                  navigate("/settings");
                }}
              />
            )}
          </Command.List>
        </Command.Dialog>
      </div>
    </>
  );
}

function Home({ listDevices, addNewDevice, accountSettings }) {
  const { t } = useTranslation();
  return (
    <>
      <Command.Group heading="Devices">
        <Item
          shortcut="S P"
          onSelect={() => {
            listDevices();
          }}
        >
          <DocsIcon />
          {t("List Devices...")}
        </Item>
        <Item
          onSelect={() => {
            addNewDevice();
          }}
        >
          <PlusIcon />
          {t("Add New Device...")}
        </Item>
      </Command.Group>
      <Command.Group heading="Settings">
        <Item
          shortcut="⇧ P"
          onSelect={() => {
            accountSettings();
          }}
        >
          <TeamsIcon />
          {t("Account Settings")}
        </Item>
      </Command.Group>
      <Command.Group heading="Help">
        <Item
          onSelect={() => {
            window.open("mailto:info@thermonova.dk");
          }}
        >
          <ContactIcon />
          {t("Contact Support")}
        </Item>
      </Command.Group>
    </>
  );
}

function Item({ children, shortcut, onSelect = () => {} }) {
  return (
    <Command.Item onSelect={onSelect}>
      {children}
      {shortcut && (
        <div cmdk-vercel-shortcuts="">
          {shortcut.split(" ").map((key) => {
            return <kbd key={key}>{key}</kbd>;
          })}
        </div>
      )}
    </Command.Item>
  );
}

function PlusIcon() {
  return (
    <svg
      fill="none"
      height="24"
      shapeRendering="geometricPrecision"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      width="24"
    >
      <path d="M12 5v14"></path>
      <path d="M5 12h14"></path>
    </svg>
  );
}

function TeamsIcon() {
  return (
    <svg
      fill="none"
      height="24"
      shapeRendering="geometricPrecision"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      width="24"
    >
      <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
      <circle cx="9" cy="7" r="4"></circle>
      <path d="M23 21v-2a4 4 0 00-3-3.87"></path>
      <path d="M16 3.13a4 4 0 010 7.75"></path>
    </svg>
  );
}

function DocsIcon() {
  return (
    <svg
      fill="none"
      height="24"
      shapeRendering="geometricPrecision"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      width="24"
    >
      <path d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8z"></path>
      <path d="M14 2v6h6"></path>
      <path d="M16 13H8"></path>
      <path d="M16 17H8"></path>
      <path d="M10 9H8"></path>
    </svg>
  );
}

function ContactIcon() {
  return (
    <svg
      fill="none"
      height="24"
      shapeRendering="geometricPrecision"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      width="24"
    >
      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
      <path d="M22 6l-10 7L2 6"></path>
    </svg>
  );
}
