import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { DataTable } from "../../../Base/DataTable";
import Header from "../../../Base/Header";
import { errorIcon } from "../../../Base/SVG";
import { getApi } from "../../../config/api";
import { RESOURCE_TYPE } from "../../../context/delete-resource-context";
import { Helmet } from "react-helmet";
import {
  DEVICE_ROW_STATUS_CODE,
  DEVICE_STATUS,
  useDeviceStatus,
} from "./useDeviceStatus";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import classNames from "classnames";
import {
  DEVICE_ATTRIBUTE,
  useSubscribeDevice,
} from "./DetailDevice/useSubscribeDevice";
import { ValueIF } from "../shared/ValueIF";
import { useAuth } from "../../../context/use-auth";
const api = getApi.instance();

const RowWrapper = ({ children, row }) => {
  const Serialnumber = row.Serialnumber;
  const { dataMap: deviceData } = useSubscribeDevice(Serialnumber);
  return children({
    wrapperData: {
      [Serialnumber]: deviceData,
    },
  });
};

const buildColumns = ({ deviceStatus, t }) => {
  return [
    {
      dataIndex: "Name",
      title: t("Name"),
      sort: 1,
    },
    {
      dataIndex: "Serialnumber",
      title: t("ID"),
      sort: 1,
    },
    {
      title: t("Status"),
      render: (_, row) => {
        const status = deviceStatus?.statuses?.[row.Serialnumber];
        return (
          <div className="deviceTable__row">
            {status?.status_code ===
            DEVICE_ROW_STATUS_CODE.READING_DEVICE_STATUS_IN_PROGRESS ? (
              <span>
                <Skeleton circle width={10} height={10} />
              </span>
            ) : (
              <>
                <p
                  className={classNames({
                    active: status?.device_status === DEVICE_STATUS.ONLINE,
                  })}
                >
                  <span></span>
                  <small> {status?.device_status}</small>
                </p>
                {status?.device_error && (
                  <div className="error">{errorIcon}</div>
                )}
              </>
            )}
          </div>
        );
      },
    },
    {
      title: t("Service water"),
      render: (_, row, index, column, wrapperData) => {
        const status = deviceStatus?.statuses?.[row.Serialnumber];
        const deviceId = row.Serialnumber;
        const deviceMeta = wrapperData?.[deviceId];
        return status?.device_status === DEVICE_STATUS.ONLINE &&
          deviceMeta[DEVICE_ATTRIBUTE.S12_DOMESTIC_HOT_WATER_INSTALLED]
          ? deviceMeta[DEVICE_ATTRIBUTE.V2_DOMESTIC_WATER_TEMPERATURE]
          : "-";
      },
    },
    {
      title: t("Central"),
      render: (_, row, index, column, wrapperData) => {
        const status = deviceStatus?.statuses?.[row.Serialnumber];
        const deviceId = row.Serialnumber;
        const deviceMeta = wrapperData?.[deviceId];
        return (
          <ValueIF
            condition={status?.device_status === DEVICE_STATUS.ONLINE}
            ifTrue={`${deviceMeta?.[DEVICE_ATTRIBUTE.V1_CH_TEMPERATURE]} °C`}
            ifFalse={"-"}
            skeletonIf={
              status?.status_code ===
                DEVICE_ROW_STATUS_CODE.READING_DEVICE_STATUS_IN_PROGRESS ||
              deviceMeta?.[DEVICE_ATTRIBUTE.V1_CH_TEMPERATURE] === undefined
            }
          />
        );
      },
    },
  ];
};

export default function TableDevices({ setMenu, menu }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const deviceStatus = useDeviceStatus();
  const { user } = useAuth();
  return (
    <>
      <Helmet>
        <title>{t("Devices - Thermonova")}</title>
      </Helmet>
      <Header title={t("Devices")} setMenu={setMenu} menu={menu} />
      <div className="auto__container">
        <div className="device tb">
          <div className="deviceBox ">
            <h3>{t("Devices")}</h3>
            <DataTable
              id="devices"
              primaryKey="Serialnumber"
              handlers={{
                rowOnClick: (row) => {
                  navigate(`/devices/detail-device/${row.Serialnumber}`);
                },
              }}
              className="deviceTable"
              columns={buildColumns({ deviceStatus, t })}
              fetcher={() =>
                api.getHeatpumps().then((response) => response.data)
              }
              deleteResourceType={RESOURCE_TYPE.DEVICE}
              beforeDeleteBegin={(row) => ({
                ...row,
                isOwner: row?.Owner === user?.identityId,
              })}
              rowWrapper={RowWrapper}
            >
              <Link
                to="/devices/add-device"
                className="button light sm"
                data-testid="Add Device"
              >
                {t("Add device")}
              </Link>
            </DataTable>
          </div>
        </div>
      </div>
    </>
  );
}
