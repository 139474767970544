import React, { useState } from "react";
import { DataTable } from "../../../../Base/DataTable";
import { errorIcon, tickIcon } from "../../../../Base/SVG";
import { getApi } from "../../../../config/api";
import { useTranslation } from "react-i18next";

const api = getApi.instance();

const PAGE_SIZE = 5;
const buildColumns = (t) => {
  return [
    {
      dataIndex: "Name",
      title: t("Error"),
    },
    {
      dataIndex: "TimeStampCreated",
      title: t("Date"),
      render: (_, row) => {
        if (!row?.TimeStampCreated) return null;
        const date = new Date(row.TimeStampCreated).toLocaleDateString();
        return <span>{date}</span>;
      },
    },
    {
      dataIndex: "TimeStampCreated",
      title: t("Time"),
      render: (value, row) => {
        const dateValue =
          row["Active"] === "False" ? row["TimeStampResolved"] : value;
        if (!value) return null;
        const date = new Date(dateValue);
        let time = date.toLocaleTimeString();
        return <span>{time}</span>;
      },
    },
    {
      dataIndex: "Active",
      title: t("Status"),
      render: (status) => {
        return status === "True" ? (
          <span className="logError">{errorIcon}</span>
        ) : (
          <span className="logActive">{tickIcon}</span>
        );
      },
    },
  ];
};

export default function Logs({ id }) {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <div>
      <DataTable
        id={`device-logs-${id}-${currentPage}`}
        primaryKey="Name"
        columns={buildColumns(t)}
        className={"deviceTable"}
        tableClassNames={"logs"}
        omitDeleteAction
        onLoadMore={() => setCurrentPage((prev) => prev + 1)}
        fetcher={() =>
          api
            .getLogs({
              Serialnumber: id,
              length: PAGE_SIZE * currentPage,
            })
            .then((response) => response.data)
        }
      >
        <div className="deviceBox__header-more">
          <h3>{t("Logs")}</h3>
        </div>
      </DataTable>
    </div>
  );
}
