import React from "react";
import { lockIcon, mailIcon } from "../../Base/SVG";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage, Form } from "formik";
import cx from "classnames";
import { useAuth } from "../../context/use-auth";
import Logo from "../../Base/Logo";
import { useTranslation } from "react-i18next";

const SignInSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string().required("Password is required"),
});

export default function SignIn() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const handleFormSubmit = async (values, helpers) => {
    try {
      await login(values.email, values.password);
      const returnUrl = searchParams.get("returnUrl") || "/";
      navigate(returnUrl);
      helpers.setSubmitting(false);
    } catch (err) {
      if (err.code === "UserNotConfirmedException") {
        sessionStorage.setItem("username", values.email);
        navigate("/account/verify-code").catch(console.error);
        return;
      }
      const result = err?.data;
      if (
        result?.challengeName &&
        result?.challengeName === "NEW_PASSWORD_REQUIRED"
      ) {
        return navigate("/account/new-password").catch(console.error);
      }
      if (
        result?.challengeName &&
        result?.challengeName === "PASSWORD_VERIFIER"
      ) {
        return navigate(
          `/account/verify?email=${encodeURIComponent(values.email)}`
        ).catch(console.error);
      }

      helpers.setStatus({ success: false });
      helpers.setErrors({ submit: err.message });
      helpers.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={SignInSchema}
      onSubmit={handleFormSubmit}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <div className="authForm">
            <div className="authForm__inner">
              <div className="authForm__logo">
                <Logo />
              </div>
              <div className="input__outer">
                <div
                  className={cx("input icon", {
                    error: errors.email && touched.email,
                  })}
                >
                  <Field name="email" type="email" placeholder={t("Email")} />
                  {mailIcon}
                  <ErrorMessage name="email">
                    {(msg) => <span className="error">{msg}</span>}
                  </ErrorMessage>
                </div>
              </div>
              <div className="input__outer">
                <div
                  className={cx("input icon", {
                    error: errors.password && touched.password,
                  })}
                >
                  <Field
                    name="password"
                    type="password"
                    placeholder={t("Password")}
                  />
                  {lockIcon}
                  <ErrorMessage name="password">
                    {(msg) => <span className="error">{msg}</span>}
                  </ErrorMessage>
                </div>
              </div>
              <Link to="/account/forgot-password" className="forget">
                {t("Forgot password?")}
              </Link>
              <div>
                {errors.submit && (
                  <span className="error">{errors.submit}</span>
                )}
              </div>
              <div className="authForm__btn">
                <button
                  type="submit"
                  className="button light"
                  disabled={isSubmitting}
                >
                  {t("Login")}
                </button>
              </div>
              <p>
                {t("Don't have an account yet?")}{" "}
                <Link to="/account/sign-up">{t("Click here")}</Link>
              </p>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
