import Dropdown from "rc-dropdown";
import "rc-dropdown/assets/index.css";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { resolveSupportedLanguages } from "./supportedLanguages";
import "./styles.scss";

export function SwitchLanguageDropdown() {
  const { t, i18n } = useTranslation();
  const currentLanguageKey = i18n.language;
  const { supportedLanguages, currentLanguage, languageListExceptCurrent } =
    useMemo(() => {
      const supportedLanguages = resolveSupportedLanguages(t);
      const currentLanguage =
        supportedLanguages[currentLanguageKey] || supportedLanguages["en-US"];
      const languageListExceptCurrent = Object.keys(supportedLanguages).filter(
        (language) => language !== currentLanguageKey
      );
      return {
        supportedLanguages,
        currentLanguage,
        languageListExceptCurrent,
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLanguageKey]);

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
  };

  const ListLanguagesDropdown = () => {
    return (
      <div>
        <div className="list-languages-dropdown">
          {languageListExceptCurrent.map((language) => {
            const { label, icon } = supportedLanguages[language];
            return (
              <div
                className="list-languages-dropdown__item"
                key={language}
                onClick={() => handleLanguageChange(language)}
              >
                <div className="list-languages-dropdown__item-icon">
                  {icon()}
                </div>
                <div className="list-languages-dropdown__item-label">
                  {label}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const Icon = currentLanguage.icon;

  return (
    <div className="switch-language-dropdown-wrapper">
      <Dropdown overlay={ListLanguagesDropdown} trigger="click">
        <Icon />
      </Dropdown>
    </div>
  );
}
