import React from "react";
import { lockIcon, mailIcon } from "../../Base/SVG";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage, Form } from "formik";
import cx from "classnames";
import { useAuth } from "../../context/use-auth";
import Logo from "../../Base/Logo";
import { MODAL_TYPE, TermsPrivacyModal } from "./TermsPrivacyModal";
import { useTranslation } from "react-i18next";

const SignUpSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string().required("Password is required"),
  accept_terms: Yup.boolean()
    .required()
    .oneOf([true], "Accept Terms is required"),
});

export default function SignUp() {
  const { register } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [modalOpen, setModalOpen] = React.useState(MODAL_TYPE.NONE);
  const { t } = useTranslation();
  const handleFormSubmit = async (values, helpers) => {
    try {
      const result = await register(values.email, values.password);
      if (!result?.UserConfirmed) {
        return navigate(
          `/account/verify-code?email=${encodeURIComponent(values.email)}`
        );
      }
      const returnUrl = searchParams.get("returnUrl") || "/";
      navigate(returnUrl);
      helpers.setSubmitting(false);
    } catch (err) {
      if (err.code === "UserNotConfirmedException") {
        sessionStorage.setItem("username", values.email);
        navigate("/account/verify-code").catch(console.error);
        return;
      }
      helpers.setStatus({ success: false });
      helpers.setErrors({ submit: err.message });
      helpers.setSubmitting(false);
    }
  };
  return (
    <>
      <Formik
        initialValues={{ email: "", password: "", accept_terms: false }}
        validationSchema={SignUpSchema}
        onSubmit={handleFormSubmit}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <div className="authForm">
              <div className="authForm__inner">
                <div className="authForm__logo">
                  <Logo />
                </div>

                <div className="input__outer">
                  <div
                    className={cx("input icon", {
                      error: errors.email && touched.email,
                    })}
                  >
                    <Field name="email" type="email" placeholder="Email" />
                    {mailIcon}
                    <ErrorMessage name="email">
                      {(msg) => <span className="error">{msg}</span>}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="input__outer">
                  <div
                    className={cx("input icon", {
                      error: errors.password && touched.password,
                    })}
                  >
                    <Field
                      name="password"
                      type="password"
                      placeholder={t("Password")}
                    />
                    {lockIcon}
                    <ErrorMessage name="password">
                      {(msg) => <span className="error">{msg}</span>}
                    </ErrorMessage>
                  </div>
                </div>
                <div>
                  {errors.submit && (
                    <span className="error">{errors.submit}</span>
                  )}
                </div>
                <div className="check">
                  <Field
                    type="checkbox"
                    name="accept_terms"
                    id="accept_terms"
                  />
                  <label htmlFor="accept_terms">
                    {t("By creating an account, you agree to our")}{" "}
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        setModalOpen(MODAL_TYPE.TERMS_AND_CONDITIONS);
                      }}
                    >
                      {t("terms and conditions")}
                    </a>{" "}
                    {t("and our")}{" "}
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        setModalOpen(MODAL_TYPE.PRIVACY_AND_POLICY);
                      }}
                    >
                      {t("privacy policy")}
                    </a>
                    . {t("Please read these carefully before proceeding")}.{" "}
                  </label>
                  <ErrorMessage name="accept_terms">
                    {(msg) => <span className="error">{msg}</span>}
                  </ErrorMessage>
                </div>
                <div className="authForm__btn">
                  <button
                    type="submit"
                    className="button light"
                    disabled={isSubmitting}
                  >
                    {t("Sign up")}
                  </button>
                </div>
                <p>
                  {t("Already have an account?")}{" "}
                  <Link to="/account/sign-in">{t("Click here")}</Link>
                </p>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <TermsPrivacyModal
        type={modalOpen}
        onClose={() => setModalOpen(MODAL_TYPE.NONE)}
      />
    </>
  );
}
