import Dialog from "rc-dialog";
import "rc-dialog/assets/index.css";
import { Button } from "../../../../Base/primitives/Button";
import useSWR from "swr";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import toast from "react-hot-toast";
import { getApi } from "../../../../config/api";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../context/use-auth";

const api = getApi.instance();

const ChangeOwnerFormSchema = Yup.object({
  new_owner: Yup.string().email().required("Required"),
});

export const ChangeOwnerModal = ({
  visible,
  setVisible,
  device,
  onComplete,
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { data: deviceUsers = [] } = useSWR("user-access");
  const [busy, setBusy] = useState(false);

  const handleSubmit = async (values, helpers) => {
    const doAction = async () => {
      const toastId = toast.loading("Changing owner...");
      try {
        setBusy(true);
        const { success, message } = await api.changeOwner(
          device.Serialnumber,
          values.new_owner
        );
        if (success) {
          toast.success(message, { id: toastId });
          setVisible(false);
          if (onComplete) {
            onComplete(values);
          }
        } else {
          toast.error(message, { id: toastId });
        }
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
        toast.error(err.message, { id: toastId });
      } finally {
        setBusy(false);
      }
    };

    doAction();
  };

  if (!device) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        new_owner: "",
      }}
      onSubmit={handleSubmit}
      validationSchema={ChangeOwnerFormSchema}
    >
      {({ values, errors, submitForm }) => (
        <Dialog
          visible={visible}
          title={t("Change Device Owner")}
          onClose={() => {
            setVisible(false);
          }}
          footer={[
            <button
              type="button"
              className="button sm"
              onClick={() => {
                setVisible(false);
              }}
            >
              {t("Cancel")}
            </button>,
            <Button
              onClick={() => {
                submitForm();
              }}
              className="button light sm confirm-btn"
              disabled={busy || !values.new_owner}
              isLoading={busy}
              text={t("Confirm")}
            />,
          ]}
        >
          {t(
            "Set new owner for device {{Serialnumber}} by selecting the user from the list below.",
            {
              Serialnumber: device.Serialnumber,
            }
          )}
          <Form>
            <div className="RadioGroupRoot">
              {deviceUsers
                ?.filter((_user) => _user.Email !== user.email)
                .map(({ Email }) => (
                  <div key={Email} className="RadioGroupItem">
                    <label className="Label">
                      <Field
                        type="radio"
                        name="new_owner"
                        value={Email}
                        className="RadioGroupIndicator"
                      />
                      {Email}
                    </label>
                  </div>
                ))}
              <div>
                <b>{t("New Owner:")}</b> {values.new_owner}
              </div>
            </div>
            <ErrorMessage name="new_owner">
              {(msg) => <span className="error">{msg}</span>}
            </ErrorMessage>
            <div>
              {errors.submit && <span className="error">{errors.submit}</span>}
            </div>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};
