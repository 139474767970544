import React from "react";
import { useTranslation } from "react-i18next";
import { SwitchLanguageDropdown } from "./SwitchLanguageDropdown";

export default function AccountLayout({ children }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="auth">
        <div className="authIntro authSection">
          <h1 className="authIntro__title">
            <span
              class="word"
              style={{
                transform: "translate(0px, 0px)",
                opacity: 1,
              }}
            >
              {t("authIntro.word.1")}
            </span>

            <span
              class="word"
              style={{
                transform: "translate(0px, 0px)",
                opacity: 1,
              }}
            >
              {t("authIntro.word.2")}
            </span>

            <span
              class="word"
              style={{
                transform: "translate(0px, 0px)",
                opacity: 1,
              }}
            >
              {t("authIntro.word.3")}
            </span>
          </h1>

          <div className="authIntro__bg">
            <div className="authIntro_background-image">
              <video
                autoPlay
                muted
                playsInline
                aria-label="Heat pump growing in size as it comes closer to the screen"
                className="video"
              >
                <source
                  src="https://cdn.prod.website-files.com/668275a4ce0083513710c512/66bb4535b089a3958255aa56_thermonova-video.mp4.txt"
                  type='video/mp4; codecs="hvc1"'
                />
                <source
                  src="https://cdn.prod.website-files.com/668275a4ce0083513710c512/66bb45356dcb4330ed38ef6c_thermonova-video.webm.txt"
                  type="video/webm"
                />
              </video>
            </div>
          </div>
        </div>
        <div className="auth-switch-language">
          <SwitchLanguageDropdown />
        </div>
        {children}
      </div>
    </>
  );
}
