import React from "react";
import { searchIcon } from "./SVG";
import { SwitchLanguageDropdown } from "./SwitchLanguageDropdown";
import { Commands } from "../module/cmdk";
import { useTranslation } from "react-i18next";

export default function Header(props) {
  const { t } = useTranslation();
  return (
    <div className="header">
      <div className="header__inner">
        <h2 className="header-title">{props.title}</h2>
        <div className="header__inner-side">
          <SwitchLanguageDropdown />
          <Commands>
            <div className="input icon">
              <input type="text" placeholder={t("Search here...")} />
              {searchIcon}
            </div>
          </Commands>
          <div
            className="burger"
            onClick={() => {
              props.setMenu(!props.menu);
            }}
          >
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
}
