import { useEffect, useState } from "react";
import useSWR from "swr";

export const DEVICE_ROW_STATUS_CODE = {
  READING_DEVICE_STATUS_IN_PROGRESS: "READING_DEVICE_STATUS_IN_PROGRESS",
  READING_DEVICE_STATUS_COMPLETE: "READING_DEVICE_STATUS_COMPLETE",
  READING_DEVICE_STATUS_FAILED: "READING_DEVICE_STATUS_FAILED",
};

export const DEVICE_STATUS = {
  ONLINE: "Online",
  OFFLINE: "Offline",
};

const primaryKey = "Serialnumber";

export function useDeviceStatus() {
  const { data: devices = [] } = useSWR("devices");
  const [loading, setLoading] = useState(false);
  const [statuses, setStatuses] = useState(statusDefaultValue(devices));
  const [processed, setProcessed] = useState(0);
  useEffect(() => {
    if (processed === devices.length) {
      setLoading(false);
    }
  }, [processed, devices.length]);
  useEffect(() => {
    setLoading(true);
    setProcessed(0);
    if (devices.length > 0) {
      devices.forEach((device) => {
        getDeviceStatus(device)
          .then(({ device, ...rest }) => {
            setStatuses((statuses) => ({
              ...statuses,
              [device[primaryKey]]: {
                status_code:
                  DEVICE_ROW_STATUS_CODE.READING_DEVICE_STATUS_COMPLETE,
                ...rest,
              },
            }));
            setProcessed((processed) => processed + 1);
          })
          .catch((error) => {
            setStatuses((statuses) => ({
              ...statuses,
              [device[primaryKey]]: {
                status_code:
                  DEVICE_ROW_STATUS_CODE.READING_DEVICE_STATUS_FAILED,
                device_status: "unknown",
              },
            }));
            setProcessed((processed) => processed + 1);
          });
      });
    }
  }, [devices]);

  return {
    loading,
    statuses,
  };
}

async function getDeviceStatus(device) {
  const device_error = device.AlarmActive === "true";
  const minsAgo15 = Date.now() - 900000;
  const onlineTimestamp = Date.parse(device.OnlineTimeStamp);
  const device_status =
    onlineTimestamp >= minsAgo15 ? DEVICE_STATUS.ONLINE : DEVICE_STATUS.OFFLINE;
  return {
    device,
    device_status,
    device_error,
  };
}

function statusDefaultValue(devices) {
  return devices.reduce((acc, device) => {
    acc[device[primaryKey]] = {
      status_code: DEVICE_ROW_STATUS_CODE.READING_DEVICE_STATUS_IN_PROGRESS,
      device_status: "",
    };
    return acc;
  }, {});
}
