import React from "react";
export default function RangeSlider(props) {
  return (
    <div className="rangeSlider">
      <input
        disabled={props.disabled}
        type="range"
        min={props.min}
        max={props.max}
        step={1}
        value={props.value}
        onChange={props.onChange}
        className="rangeSlider__input"
        onBlur={props.onBlur}
      />
      <span></span>
    </div>
  );
}
