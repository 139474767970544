import React from "react";
import { DEVICE_ATTRIBUTE, useSubscribeDevice } from "./useSubscribeDevice";
import { ValueIF, Value } from "../../shared/ValueIF";
import { useTranslation } from "react-i18next";

export default function Operational({ id }) {
  const { t } = useTranslation();
  const { dataMap: deviceMeta } = useSubscribeDevice(id);
  return (
    <div className="deviceTable ">
      <table className="opera">
        <thead>
          <tr>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {deviceMeta[DEVICE_ATTRIBUTE.S13] === false && (
              <td>
                <div className="deviceTable__row">
                  <p>{t("Central Heating Temperature")}</p>
                  <p>
                    <Value
                      value={deviceMeta[DEVICE_ATTRIBUTE.V1_CH_TEMPERATURE]}
                      skeletonIf={
                        deviceMeta[DEVICE_ATTRIBUTE.V1_CH_TEMPERATURE] ===
                        undefined
                      }
                    />
                    °C
                  </p>
                </div>
              </td>
            )}

            {deviceMeta[DEVICE_ATTRIBUTE.S12_DOMESTIC_HOT_WATER_INSTALLED] && (
              <td>
                <div className="deviceTable__row">
                  <p>{t("Domestic Water Temperature")}</p>
                  <p>
                    <Value
                      value={
                        deviceMeta[
                          DEVICE_ATTRIBUTE.V2_DOMESTIC_WATER_TEMPERATURE
                        ]
                      }
                      skeletonIf={
                        deviceMeta[
                          DEVICE_ATTRIBUTE.V2_DOMESTIC_WATER_TEMPERATURE
                        ] === undefined
                      }
                    />
                    °C
                  </p>
                </div>
              </td>
            )}
          </tr>
          <tr>
            <td>
              <div className="deviceTable__row">
                <p>{t("Forward Temperature")}</p>
                <p>
                  <ValueIF
                    condition={
                      deviceMeta[DEVICE_ATTRIBUTE.S8_ENERGYMETER_CONNECTED]
                    }
                    ifTrue={deviceMeta[DEVICE_ATTRIBUTE.V13_EM_TEMP_OUT]}
                    ifFalse={
                      deviceMeta[DEVICE_ATTRIBUTE.V3_FORWARD_TEMPERATURE]
                    }
                  />
                  °C
                </p>
              </div>
            </td>
            <td>
              <div className="deviceTable__row">
                <p>{t("Return Temperature")}</p>
                <p>
                  <ValueIF
                    condition={
                      deviceMeta[DEVICE_ATTRIBUTE.S8_ENERGYMETER_CONNECTED]
                    }
                    ifTrue={deviceMeta[DEVICE_ATTRIBUTE.V12_EM_TEMP_IN]}
                    ifFalse={deviceMeta[DEVICE_ATTRIBUTE.V4_RETURN_TEMPERATURE]}
                  />{" "}
                  °C
                </p>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="deviceTable__row">
                <p>{t("Load Heatpump")}</p>
                <p>
                  <Value
                    value={deviceMeta[DEVICE_ATTRIBUTE.V5_LOAD_HEATPUMP]}
                    skeletonIf={
                      deviceMeta[DEVICE_ATTRIBUTE.V5_LOAD_HEATPUMP] ===
                      undefined
                    }
                  />
                  %
                </p>
              </div>
            </td>
            <td>
              {deviceMeta[DEVICE_ATTRIBUTE.S11_SUPPLY_HEATING_INSTALLED] && (
                <div className="deviceTable__row">
                  <p>{t("Load Supplyheater")}</p>
                  <p>
                    {
                      <Value
                        value={
                          deviceMeta[DEVICE_ATTRIBUTE.V6_LOAD_SUPPLYHEATER]
                        }
                        skeletonIf={
                          deviceMeta[DEVICE_ATTRIBUTE.V6_LOAD_SUPPLYHEATER] ===
                          undefined
                        }
                      />
                    }
                    %
                  </p>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td>
              <div className="deviceTable__row">
                <p>{t("Power Consumption")}</p>
                <p>
                  <Value
                    value={deviceMeta[DEVICE_ATTRIBUTE.V7_POWER_CONSUMPTION]}
                    skeletonIf={
                      deviceMeta[DEVICE_ATTRIBUTE.V7_POWER_CONSUMPTION] ===
                      undefined
                    }
                  />
                  MWh
                </p>
              </div>
            </td>
            <td>
              <div className="deviceTable__row">
                <p>{t("Energy Produced")}</p>
                <p>
                  <ValueIF
                    condition={
                      deviceMeta[DEVICE_ATTRIBUTE.S8_ENERGYMETER_CONNECTED]
                    }
                    ifTrue={`${
                      deviceMeta[DEVICE_ATTRIBUTE.V8_ENERGY_PRODUCED]
                    } MWh`}
                    ifFalse={t("Not installed")}
                    skeletonIf={
                      !deviceMeta[DEVICE_ATTRIBUTE.V8_ENERGY_PRODUCED]
                    }
                  />
                </p>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="deviceTable__row">
                <p>{t("Flow")}</p>
                <p>
                  <ValueIF
                    condition={
                      deviceMeta[DEVICE_ATTRIBUTE.S8_ENERGYMETER_CONNECTED]
                    }
                    ifTrue={`${deviceMeta[DEVICE_ATTRIBUTE.V9_FLOW]} m³/h`}
                    ifFalse={t("Not installed")}
                    skeletonIf={
                      deviceMeta[DEVICE_ATTRIBUTE.V9_FLOW] === undefined
                    }
                  />
                </p>
              </div>
            </td>
            <td>
              <div className="deviceTable__row">
                <p>{t("COP & SCOP")}</p>
                <p>
                  <ValueIF
                    condition={
                      deviceMeta[DEVICE_ATTRIBUTE.S8_ENERGYMETER_CONNECTED]
                    }
                    ifTrue={`${deviceMeta[DEVICE_ATTRIBUTE.V10_COP]} / ${
                      deviceMeta[DEVICE_ATTRIBUTE.V11_SCOP]
                    }`}
                    ifFalse={t("Not installed")}
                    skeletonIf={
                      !deviceMeta[DEVICE_ATTRIBUTE.V10_COP] ||
                      !deviceMeta[DEVICE_ATTRIBUTE.V11_SCOP]
                    }
                  />
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
