import { produce } from "immer";
import PropTypes from "prop-types";
import Dialog from "rc-dialog";
import "rc-dialog/assets/index.css";
import { createContext, useContext, useReducer, useState } from "react";
import { getApi } from "../config/api";
import { Button } from "../Base/primitives/Button";
import toast, { Toaster } from "react-hot-toast";
import { useAuth } from "./use-auth";
import { useTranslation } from "react-i18next";
import { ChangeOwnerModal } from "../Pages/Devices/Pages/DetailDevice/ChangeOwnerModal";
const api = getApi.instance();

export const RESOURCE_TYPE = {
  DEVICE: "DEVICE",
  USER_ACCESS: "USER_ACCESS",
};

/**
  Meta should follow this structure:
  {
    item
  }
*/

const initialState = {
  resourceType: "",
  busyDeletingResource: false,
  errorDeletingResource: null,
  meta: null,
};

const ActionType = {
  BEGIN_DELETING: "BEGIN_DELETING",
  RESET: "RESET",
  SET_ERROR: "SET_ERROR",
  SET_BUSY_DELETING: "SET_BUSY_DELETING",
  SET_META_IS_OWNER: "SET_META_IS_OWNER",
};

const handlers = {
  BEGIN_DELETING: (state, action) => {
    const { resourceType, meta } = action.payload;
    const nextState = produce(state, (draft) => {
      draft.resourceType = resourceType;
      draft.meta = meta;
      draft.errorDeletingResource = null;
    });
    return nextState;
  },
  SET_ERROR: (state, action) => {
    const { error } = action.payload;
    const nextState = produce(state, (draft) => {
      draft.errorDeletingResource = error;
    });
    return nextState;
  },
  SET_BUSY_DELETING: (state, action) => {
    const { busy } = action.payload;
    const nextState = produce(state, (draft) => {
      draft.busyDeletingResource = busy;
    });
    return nextState;
  },
  SET_META_IS_OWNER: (state, action) => {
    const { isOwner } = action.payload;
    const nextState = produce(state, (draft) => {
      draft.meta.item.isOwner = isOwner;
    });
    return nextState;
  },
  RESET: () => {
    return initialState;
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const DeleteResourceContext = createContext({
  ...initialState,
});

const getTitleForResourceType = (resourceType, { t }) => {
  switch (resourceType) {
    case RESOURCE_TYPE.DEVICE:
      return t("Delete device");
    case RESOURCE_TYPE.USER_ACCESS:
      return t("Delete user access");
    default:
      return "";
  }
};

const getBodyForResourceType = (resourceType, item, { t }) => {
  if (item.isOwner) {
    return t(
      `You are the owner of this device. You need to transfer ownership to another user before you can delete it.`
    );
  }

  switch (resourceType) {
    case RESOURCE_TYPE.DEVICE:
      return t(`Are you sure you want to delete device {{deviceNumber}}?`, {
        deviceNumber: item.Serialnumber,
      });
    case RESOURCE_TYPE.USER_ACCESS:
      return t(
        `Are you sure you want to delete user access for {{userIdentifier}}?`,
        {
          userIdentifier: item.user.Email,
        }
      );
    default:
      return "";
  }
};

const executor = (resourceType, meta, { user }) => {
  switch (resourceType) {
    case RESOURCE_TYPE.DEVICE:
      return api.revokeUserAccess(meta.item.Serialnumber, user.email);
    case RESOURCE_TYPE.USER_ACCESS:
      return api.revokeUserAccess(meta.item.Serialnumber, meta.item.user.Email);
    default:
      return null;
  }
};

export const DeleteResourceProvider = (props) => {
  const { t } = useTranslation();
  const { children } = props;
  const auth = useAuth();

  const [state, dispatch] = useReducer(reducer, initialState);

  const [changeOwnerModalVisible, setChangeOwnerModalVisible] = useState(false);

  const handleChangeOwner = () => {
    setChangeOwnerModalVisible(true);
  };

  const reset = () => {
    dispatch({
      type: ActionType.RESET,
    });
  };

  const begin = (resourceType, meta) => {
    dispatch({
      type: ActionType.BEGIN_DELETING,
      payload: {
        resourceType,
        meta,
      },
    });
  };

  const setError = (error) => {
    dispatch({
      type: ActionType.SET_ERROR,
      payload: {
        error,
      },
    });
  };

  const setBusyDeleting = (busy) => {
    dispatch({
      type: ActionType.SET_BUSY_DELETING,
      payload: {
        busy,
      },
    });
  };

  const setMetaIsOwner = (isOwner) => {
    dispatch({
      type: ActionType.SET_META_IS_OWNER,
      payload: {
        isOwner,
      },
    });
  };

  const onConfirm = async (props) => {
    const skipCheckOwner = props?.skipCheckOwner || false;
    const afterDelete = state.meta?.afterDelete;
    if (!skipCheckOwner) {
      if (state?.meta?.item?.isOwner) {
        handleChangeOwner();
        return;
      }
    }

    setBusyDeleting(true);
    const toastId = toast.loading("Deleting resource...");
    executor(state.resourceType, state.meta, {
      user: auth.user,
    })
      .then(({ message }) => {
        toast.success(message || "Resource deleted", {
          id: toastId,
        });
        afterDelete();
        reset();
      })
      .catch((e) => {
        const errorMessage = e.message || "Failed to delete resource";
        toast.error(errorMessage, {
          id: toastId,
        });
        setError(errorMessage);
      })
      .finally(() => {
        setBusyDeleting(false);
      });
  };

  return (
    <DeleteResourceContext.Provider
      value={{
        ...state,
        begin,
      }}
    >
      <>
        {state.resourceType && (
          <Dialog
            visible
            title={getTitleForResourceType(state.resourceType, {
              t,
            })}
            onClose={() => {
              reset();
            }}
            footer={[
              <button
                type="button"
                className="button sm mr-2"
                onClick={() => {
                  reset();
                }}
              >
                {t("Cancel")}
              </button>,
              <Button
                onClick={() => {
                  onConfirm();
                }}
                className="button light sm"
                disabled={state.busyDeletingResource}
                isLoading={state.busyDeletingResource}
                text={t("Confirm")}
              />,
            ]}
          >
            {getBodyForResourceType(state.resourceType, state.meta?.item, {
              t,
            })}
          </Dialog>
        )}
        <ChangeOwnerModal
          visible={changeOwnerModalVisible}
          setVisible={setChangeOwnerModalVisible}
          device={state.meta?.item}
          onComplete={() => {
            setMetaIsOwner(false);
            setBusyDeleting(true);
            setTimeout(() => {
              onConfirm({
                skipCheckOwner: true,
              });
            }, 2000);
          }}
        />
        {children}
      </>

      <Toaster />
    </DeleteResourceContext.Provider>
  );
};

DeleteResourceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useDeleteResource = () => {
  const context = useContext(DeleteResourceContext);
  if (context === undefined) {
    throw new Error(
      "useDeleteResource must be used within a DeleteResourceProvider"
    );
  }
  return context;
};

export const DeleteResourceConsumer = DeleteResourceContext.Consumer;
