import Dialog from "rc-dialog";
import "rc-dialog/assets/index.css";
import { useEffect, useState } from "react";
import Loader from "../../../Base/primitives/Loader";
import ReactMarkdown from "react-markdown";
import "./index.scss";
import { useTranslation } from "react-i18next";

export const MODAL_TYPE = {
  TERMS_AND_CONDITIONS: "TERMS_AND_CONDITIONS",
  PRIVACY_AND_POLICY: "PRIVACY_AND_POLICY",
  NONE: "NONE",
};

const SOURCE_URL = {
  [MODAL_TYPE.PRIVACY_AND_POLICY]: process.env.REACT_APP_PRIVACY_POLICY_URL,
  [MODAL_TYPE.TERMS_AND_CONDITIONS]: process.env.REACT_APP_TERMS_OF_SERVICE_URL,
};

export function TermsPrivacyModal({ type, onClose }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState("");
  const title =
    type === MODAL_TYPE.TERMS_AND_CONDITIONS
      ? t("Terms and Conditions")
      : t("Privacy and Policy");

  useEffect(() => {
    if (
      [MODAL_TYPE.TERMS_AND_CONDITIONS, MODAL_TYPE.PRIVACY_AND_POLICY].includes(
        type
      )
    ) {
      setIsLoading(true);
      const performFetch = async () => {
        const response = await fetch(SOURCE_URL[type]);
        const text = await response.text();
        setContent(text);
        setIsLoading(false);
      };

      performFetch();
    }
  }, [type]);

  const handleOnClose = () => {
    setContent("");
    onClose();
  };

  return (
    <Dialog
      className="terms-privacy-modal"
      style={{ width: "80%" }}
      visible={type !== MODAL_TYPE.NONE}
      title={title}
      onClose={handleOnClose}
      footer={[
        <button type="button" className="button  sm" onClick={handleOnClose}>
          {t("Cancel")}
        </button>,
      ]}
    >
      {isLoading && (
        <div className="flex flex-col items-center justify-center">
          <Loader width={50} height={50} className={"spinner"} stroke="black" />
          <p className="text-center">{t("Loading content")}</p>
        </div>
      )}

      {!isLoading && (
        <div>
          <ReactMarkdown>{content}</ReactMarkdown>
        </div>
      )}
    </Dialog>
  );
}
