import React, { useMemo } from "react";
import { lockIcon, mailIcon } from "../../Base/SVG";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage, Form } from "formik";
import cx from "classnames";
import { useAuth } from "../../context/use-auth";
import toast, { Toaster } from "react-hot-toast";
import Logo from "../../Base/Logo";
import { useTranslation } from "react-i18next";

const VerifyCodeSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  verification_code: Yup.string().required("Verification code is required"),
});

export default function VerifyCode() {
  const { t } = useTranslation();
  const { verifyCode, resendCode } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const emailFromSearchParam = useMemo(() => {
    return decodeURIComponent(searchParams.get("email") || "");
  }, [searchParams]);

  const handleFormSubmit = async (values, helpers) => {
    try {
      await verifyCode(values.email, values.verification_code);
      navigate("/account/sign-in");
      helpers.setSubmitting(false);
    } catch (err) {
      helpers.setStatus({ success: false });
      helpers.setErrors({ submit: err.message });
      helpers.setSubmitting(false);
    }
  };

  const handleRequestNewVerificationCodeOnClick = async (e) => {
    e.preventDefault();
    toast.promise(resendCode(emailFromSearchParam), {
      loading: t("Sending new code..."),
      success: t("New code sent!"),
      error: t("Error sending new code."),
    });
  };

  return (
    <Formik
      initialValues={{ email: emailFromSearchParam, code: "" }}
      validationSchema={VerifyCodeSchema}
      onSubmit={handleFormSubmit}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <div className="authForm">
            <div className="authForm__inner">
              <div className="authForm__logo">
                <Logo />
              </div>

              <div className="input__outer">
                <div
                  className={cx("input icon", {
                    error: errors.email && touched.email,
                  })}
                >
                  <Field name="email" type="email" placeholder={t("Email")} />
                  {mailIcon}
                  <ErrorMessage name="email">
                    {(msg) => <span className="error">{msg}</span>}
                  </ErrorMessage>
                </div>
              </div>
              <div className="input__outer">
                <div
                  className={cx("input icon", {
                    error:
                      errors.verification_code && touched.verification_code,
                  })}
                >
                  <Field
                    name="verification_code"
                    type="input"
                    placeholder={t("Verification Code")}
                  />
                  {lockIcon}
                  <ErrorMessage name="verification_code">
                    {(msg) => <span className="error">{msg}</span>}
                  </ErrorMessage>
                </div>
              </div>
              <div>
                {errors.submit && (
                  <span className="error">{errors.submit}</span>
                )}
              </div>
              <div className="authForm__btn">
                <button
                  type="submit"
                  className="button light"
                  disabled={isSubmitting}
                >
                  {t("Verify Code")}
                </button>
              </div>

              <p>
                <Link to="" onClick={handleRequestNewVerificationCodeOnClick}>
                  {t("Request new verification code.")}
                </Link>
              </p>
            </div>
            <Toaster />
          </div>
        </Form>
      )}
    </Formik>
  );
}
