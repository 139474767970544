import axios from "axios";
import { Auth } from "../Base/Config/amplifyConfig";

export const setupAxios = () => {
  const instance = axios.create({
    baseURL: process.env["REACT_APP_API_BASE_URL"],
  });
  instance.interceptors.request.use(async (config) => {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken();
    const token = idToken.getJwtToken();
    if (token && config.headers) {
      config.headers["Authorization"] = token;
    }
    return config;
  });
  return instance;
};
export default setupAxios;
