import React, { useEffect, useMemo } from "react";
import Header from "../../../../Base/Header";
import Logs from "./Logs";
import Users from "./Users";
import Operational from "./Operational";
import Settings from "./Settings";
import { useParams } from "react-router-dom";
import { getApi } from "../../../../config/api";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../context/use-auth";
import { PubSub } from "@aws-amplify/pubsub";

const api = getApi.instance();

export default function DetailDevice({ menu, setMenu }) {
  const { deviceId } = useParams();
  const { user } = useAuth();
  const { t } = useTranslation();
  const { data: device } = useSWR(`/devices/${deviceId}`, async () => {
    const response = await api.getHeatpump(deviceId);
    return response.data;
  });

  const isOwner = useMemo(() => {
    if (!device || !user) return false;
    return device?.Owner === user?.identityId;
  }, [device, user]);

  const identityID = user?.identityId;
  const handlerTopic = "HP/" + device?.Serialnumber + "/handler";

  useEffect(() => {
    function timeout(delay) {
      return new Promise((res) => setTimeout(res, delay));
    }
    if (identityID !== " ") {
      timeout(2000).then(() => {
        PubSub.publish(handlerTopic, { user: "True", id: identityID });
        window.addEventListener("unload", onClose);
      });
    }
    function onClose() {
      PubSub.publish(handlerTopic, { user: "False", id: identityID });
    }

    return () => {
      window.removeEventListener("unload", onClose);
      if (identityID !== " ") {
        PubSub.publish(handlerTopic, { user: "False", id: identityID });
      }
    };
  }, [handlerTopic, identityID]);

  return (
    <>
      <Header title={device?.Name} menu={menu} setMenu={setMenu} />
      <div className="auto__container">
        <div className="device dd">
          <div className="device__row">
            <div className="device__col">
              <Settings id={deviceId} />
              <div className="deviceBox">
                <div className="deviceBox__header">
                  <h3>{t("Operational status")}</h3>
                </div>
                <Operational id={deviceId} />
              </div>
            </div>
            <div className="device__col">
              <div className="deviceBox">
                <Logs id={deviceId} />
              </div>
              {isOwner && device?.Serialnumber && (
                <div className="deviceBox">
                  <Users device={device} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
