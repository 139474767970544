import React from "react";
import Header from "../../Base/Header";
import Account from "./Account";
import ChangePassword from "./ChangePassword";
import Notifications from "./Notifications";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { LogoutSection } from "./LogoutSection";

export default function Settings({ setMenu, menu }) {
  const { t } = useTranslation();
  return (
    <div className="layout">
      <Helmet>
        <title>{t("Settings - Thermonova")}</title>
      </Helmet>
      <Header title={"Settings"} setMenu={setMenu} menu={menu} />
      <div className="auto__container">
        <div className="layout__inner">
          <div className="sett">
            <Account />
            <ChangePassword />
            <Notifications />
            <LogoutSection />
          </div>
        </div>
      </div>
    </div>
  );
}
