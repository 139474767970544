import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/use-auth";

export function LogoutSection() {
  const { t } = useTranslation();
  const auth = useAuth();
  return (
    <div className="settBox">
      <div className="settBox__header">
        <h3>{t("Log Out")}</h3>
      </div>
      <p>
        {t(
          "You can log out of your account here. If you log out, you will need to log in again to access your account."
        )}
      </p>
      <button
        type="button"
        onClick={async (e) => {
          e.preventDefault();
          await auth.logout();
        }}
        className="button light sm"
      >
        {t("Log Out")}
      </button>
    </div>
  );
}
