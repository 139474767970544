import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/use-auth";

export const GuestGuard = (props) => {
  const { children } = props;
  const auth = useAuth();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);

  useEffect(
    () => {
      if (auth.isAuthenticated) {
        navigate("/");
      } else {
        setChecked(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!checked) {
    return null;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // not authenticated / authorized.

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};
