import classNames from "classnames";
import "./Button.scss";
import Loader from "./Loader";

export function Button(props) {
  const {
    onClick,
    className,
    text,
    disabled,
    style,
    isLoading = false,
  } = props;
  return (
    <button
      type="button"
      disabled={disabled}
      className={classNames("button light sm submit-btn", className)}
      onClick={onClick}
      style={style}
    >
      {!isLoading ? text : <Loader className="spinner" />}
    </button>
  );
}
